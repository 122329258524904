






















































































































































import { Vue } from 'vue-property-decorator';
import { SetFavoriteStatus } from '@/store/users';
import { SendDirectMessage } from '@/store/messages';
import { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import MSlotPicker from '@/components/TimeSlots/SlotPicker.vue';
import { mapGetters } from 'vuex';
import { BookSession } from '@/store/matching';

export default Vue.extend({
  name: 'UserDetail',
  components: { MSlotPicker },
  props: {
    user: { type: Object as PropType<UserEntity> },
  },
  data: () => ({
    message: '',
    isLoading: false,
    selectedSlot: undefined,
    selectedWeekday: undefined,
  }),
  computed: {
    matchesNumber() {
      return 0;
    },
    haveSlots() {
      const slots = this.user?.attributes.timeSlots?.slots;
      return slots && Object.keys(slots).length > 0;
    },
    ...mapGetters('tenant', {
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    isTimeSlotsEnabled() {
      return this.isFeatureEnabled('timeSlots');
    },
    isSendBtnEnabled() {
      if (this.selectedWeekday) return this.selectedSlot != null;
      else return this.message?.length > 0;
    },
  },
  methods: {
    isFavorite() {
      return this.user.isFavorite;
    },
    async toggleFavorite() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },
    async sendMessage() {
      this.isLoading = true;
      try {
        const success = await this.$store.dispatch(new SendDirectMessage(this.user.id, this.message));
        if (success) this.message = '';
      } finally {
        this.isLoading = false;
      }
    },
    async sendAppointment() {
      this.isLoading = true;
      try {
        const success = await this.$store.dispatch(
          new BookSession(this.user, this.selectedWeekday, this.selectedSlot, this.message),
        );
        if (success) {
          if (success) this.message = '';
          this.$refs.slotPicker.reset();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async send() {
      if (this.selectedSlot) await this.sendAppointment();
      else await this.sendMessage();
    },
    buildUrl(value: string) {
      if (value.startsWith('http://') || value.startsWith('https://')) return value;
      return `https://${value}`;
    },
  },
});
