import { MessagesLoadingPage, MessagesMutations, MessagesState } from './types';
import { MutationTree } from 'vuex';
import { MessageEntity, RoomEntity } from '@mentessa/types';

export const mutations: MutationTree<MessagesState> = {
  [MessagesMutations.SetRooms](state: MessagesState, rooms: RoomEntity[]) {
    rooms.forEach((room) => {
      room.members.forEach((member) => {
        if (!member.user.identity.attributes.image) {
          member.user.identity.attributes.image = '/user-empty.png';
        }
      });
    });
    state.rooms = rooms;
  },
  [MessagesMutations.SetMessages](state: MessagesState, messages: MessageEntity[]) {
    state.messages = messages;
  },
  [MessagesMutations.AppendMessage](state: MessagesState, message: MessageEntity) {
    state.messages.push(message);
  },
  [MessagesMutations.SetTimer](state: MessagesState, timer?: number) {
    if (timer == null && state.fetchTimer != null) {
      clearInterval(state.fetchTimer);
      state.fetchTimer = undefined;
    } else {
      state.fetchTimer = timer;
    }
  },
  [MessagesMutations.SetLastFetchTime](state: MessagesState, time: Date) {
    state.lastFetchTime = time;
  },
  [MessagesMutations.SetUnreadMessageCount](state: MessagesState, count: number) {
    state.unreadMessageCount = count;
  },
  [MessagesMutations.SetActiveRoom](state: MessagesState, room: RoomEntity) {
    state.activeRoom = room;
  },
  [MessagesMutations.ReadActiveRoom](state: MessagesState) {
    if (state.activeRoom == null) {
      return;
    }
    state.messages?.forEach((msg) => {
      msg.isRead = true;
    });
    state.activeRoom.hasUnread = false;
    const sourceActiveRoom = state.rooms.find((room) => room.id === state.activeRoom.id);
    sourceActiveRoom.hasUnread = false;
  },
  [MessagesMutations.AddRequestsPage](state: MessagesState, data: MessagesLoadingPage) {
    data.items.forEach((message) => {
      if (!message.user.identity.attributes.image) {
        message.user.identity.attributes.image = '/user-empty.png';
      }
    });
    state.requests.items.push(...data.items);
    Object.assign(state.requests.pagination, data.meta);
  },
  [MessagesMutations.SetRequestsLoadingFlag](state: MessagesState, isLoading: boolean) {
    state.requests.isLoading = isLoading;
  },
  [MessagesMutations.AppendRequest](state: MessagesState, request: MessageEntity) {
    state.requests.items.push(request);
  },
  [MessagesMutations.RemoveRequest](state: MessagesState, request: MessageEntity) {
    const rId = state.requests.items.findIndex((r) => r.id === request.id);
    state.requests.items.splice(rId, 1);
  },
  [MessagesMutations.SetOnlyMyRequests](state: MessagesState, enabled: boolean) {
    state.requests.onlyMy = enabled;
  },
  [MessagesMutations.SetHashtagsForRequests](state: MessagesState, tags: string[]) {
    state.requests.hashtags = tags;
  },
  [MessagesMutations.ClearRequests](state: MessagesState, force = false) {
    if (state.requests.isLoading && !force) {
      console.warn('Trying to clear requests while loading data. Skip');
      return;
    }
    state.requests.items.splice(0);
    state.requests.pagination.currentPage = 0;
    state.requests.pagination.itemCount = 0;
    state.requests.pagination.totalItems = 0;
    state.requests.pagination.totalPages = 0;
  },
  [MessagesMutations.SetRequest](
    state: MessagesState,
    options: { request: MessageEntity; dto: Partial<MessageEntity> },
  ) {
    if (options.request.text !== options.dto.text) {
      options.request.text = options.dto.text;
    }
    if (options.request.attrs.description !== options.dto.attrs.description) {
      options.request.attrs.description = options.dto.attrs.description;
    }
    if (options.request.attrs.url !== options.dto.attrs.url) {
      options.request.attrs.url = options.dto.attrs.url;
    }
    if (options.request.attrs.image !== options.dto.attrs.image) {
      options.request.attrs.image = options.dto.attrs.image;
    }
  },
};
