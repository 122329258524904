






























import { InviteUser } from '@/store/users';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import TabMembers from '@/components/Tabs/TabMembers.vue';

export default Vue.extend({
  name: 'Admin',
  components: {
    TabMembers,
  },
  data: () => ({
    isFormValid: true,
    inviteEmail: '',
    progressBar: false,
    isAdmin: false,
    isMentor: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    isMatchingEnabled() {
      return this.isFeatureEnabled('matching');
    },
  },
  methods: {
    isEmailValid(email: string): boolean {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    clearEmail(): void {
      this.inviteEmail = '';
    },
    sendInvitation(): void {
      this.$store.dispatch(new InviteUser(this.inviteEmail, this.isMentor, this.isAdmin));
    },
  },
});
