const isProduction = process.env.NODE_ENV === 'production';

function getBoolean(name, defaultValue = false) {
  if (process.env[name] == null) {
    return defaultValue;
  }
  return ['true', '1'].includes(process.env[name].toLowerCase());
}

export const loggerOptions = {
  isEnabled: process.env.VUE_APP_LOG_ENABLE || true,
  logLevel: process.env.VUE_APP_LOG_LEVEL || isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

export const messageOptions = {
  autoFetch: getBoolean('VUE_APP_MESSAGE_FETCH_AUTO', true),
  fetchInterval: Number(process.env.VUE_APP_MESSAGE_FETCH_INTERVAL) || 5 * 60 * 1000,
};

export const notificationOptions = {
  snackbar: {
    showTime: Number(process.env.VUE_APP_SNACKBAR_SHOW_TIME) || 3 * 1000,
  },
};

const host = window.location.host;
const hostParts = host.split('.');
function getNewCommunitySubdomain() {
  return process.env.VUE_APP_NEW_COMMUNITY_SUBDOMAIN || 'new';
}

export enum AppType {
  NewCommunity,
  UseCommunity,
}

export const app = {
  newCommunitySubdomain: getNewCommunitySubdomain(),
  community: hostParts[0],
  type: hostParts[0] === getNewCommunitySubdomain() ? AppType.NewCommunity : AppType.UseCommunity,
};
