import { Action } from '@/store/types';
import { GeonamesInstance } from 'geonames.js/src/geonames-types';

export const moduleName = 'utils';

export interface Language {
  code: string;
  name: string;
}

export interface UtilsState {
  languages: Language[];
  geonames: GeonamesInstance;
}

export enum UtilsActions {
  LoadLanguages = '[Utils] LoadLanguages',
  InitGeonames = '[Utils] InitGeonames',
}

export enum UtilsMutations {
  SetLanguages = '[Utils] Set Languages',
  SetGeonames = '[Utils] Set Geonames',
}

export class LoadLanguages implements Action {
  constructor(public locale: string) {
    this.locale = locale;
  }
  type = `${moduleName}/${UtilsActions.LoadLanguages}`;
}

export class InitGeonames implements Action {
  constructor(public locale: string) {
    this.locale = locale;
  }
  type = `${moduleName}/${UtilsActions.InitGeonames}`;
}
