import Vue from 'vue';
import './logger';
import App from './App.vue';
import router from './router';
import store from './store';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import { api } from '@/store/api';
import { Initialize } from '@/store/types';
import { KeycloakConfig } from '@mentessa/types';
import { app, AppType } from '@/config';
import InviteApp from '@/InviteApp.vue';

Vue.config.productionTip = false;

function tokenInterceptor() {
  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

function unauthorizedInterceptor(keycloak) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        console.error(`Unauthorized request.`);
        return keycloak.logout();
      } else if (error.response.status === 403) {
        console.error(`You have no access to ${error.response.config.url}`);
      }
      return Promise.reject(error);
    },
  );
}

function createVueApp(loadData = true) {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    async created() {
      Vue.prototype.$log.debug('App created');
      await this.$store.dispatch(new Initialize(loadData));
    },
  }).$mount('#app');
}

function createMainApp() {
  api
    .get('exists')
    .then((response) => {
      const realmConfig: KeycloakConfig = response?.data;
      console.log('User realm:', realmConfig);
      Vue.use(VueKeyCloak, {
        onReady: (keycloak) => {
          tokenInterceptor();
          unauthorizedInterceptor(keycloak);
          createVueApp(app.type === AppType.UseCommunity);
        },
        init: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        config: realmConfig,
      });
    })
    .catch(() => {
      window.location.href = 'https://mentessa.com';
    });
}

function createInvitationApp() {
  new Vue({
    store,
    vuetify,
    i18n,
    render: (h) => h(InviteApp),
    async created() {
      Vue.prototype.$log.debug('Invite App created');
    },
  }).$mount('#app');
}

function createApp() {
  if (window.location.pathname === '/invite') {
    createInvitationApp();
  } else {
    createMainApp();
  }
}

createApp();
