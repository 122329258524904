


































import Vue from 'vue';

export default Vue.extend({
  name: 'm-matching-message-form',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    message: '',
  }),
  mounted() {
    this.message = this.$t('matching.defaultMatchedMessage');
  },
  methods: {
    sendMessage() {
      this.$emit('onMessageSent', this.message);
    },
  },
});
