



































import Vue from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { between, required } from 'vee-validate/dist/rules';
import { CreateInvite } from '@/store/invites';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: 'The {_field_} can not be empty',
});
extend('between', {
  ...between,
  message: 'The {_field_} must be a number between {min} and {max}',
});

export default Vue.extend({
  name: 'm-new-invite-form',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    limit: 10,
    name: '',
    isAdmin: false,
    isMentor: false,
  }),
  methods: {
    closeModal() {
      this.$emit('closeModal', true);
    },
    createInvite() {
      if (
        this.$store.dispatch(
          new CreateInvite({
            limit: this.limit,
            attributes: { name: this.name, isMentor: this.isMentor, isAdmin: this.isAdmin },
          }),
        )
      ) {
        this.closeModal();
      }
    },
  },
});
