






















import Vue from 'vue';

/*

ToDo: Default height = 48px.
ToDo: Default width ?

 */
export default Vue.extend({
  name: 'm-autocomplete-pure',
  props: {
    labelTop: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    maxWidth: { type: String, default: '320px' },
    value: undefined,
    // filter: Function,
  },
  data: () => ({
    search: null,
  }),
  computed: {
    searchInput: {
      get() {
        return this.search;
      },
      set(newValue) {
        if (newValue != this.search) {
          this.search = newValue;
          this.$emit('onSearchChanged', newValue);
        }
      },
    },
    style() {
      return {
        '--preview-max-width': this.maxWidth,
      };
    },
  },
});
