import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Close from './components/Icons/Close.vue';
import HandshakeEmoji from './components/Icons/HandshakeEmoji.vue';
import Envelope from './components/Icons/Envelope.vue';
import { IconsOptions } from 'vuetify/types/services/icons';

const options: IconsOptions = {
  iconfont: 'md',
  values: {
    c3lose: {
      component: Close,
    },
    handshakee: {
      component: HandshakeEmoji,
    },
    envelope: {
      component: Envelope,
    },
  },
};

export default options;
