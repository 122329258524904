import { TenantMutations, TenantState } from '@/store/tenant/types';
import { MutationTree } from 'vuex';
import { TenantEntity } from '@mentessa/types';

export const mutations: MutationTree<TenantState> = {
  [TenantMutations.SetTenant](state: TenantState, tenant: TenantEntity) {
    state.tenant = tenant;
  },

  [TenantMutations.SetLocations](state: TenantState, locations: { name: string }[]) {
    state.locations = locations;
  },

  [TenantMutations.SetUsernames](
    state: TenantState,
    usernames: { id?: number; firstName: string; lastName: string; image: string }[],
  ) {
    state.usernames = usernames.map((username) => ({
      ...username,
      name: `${username.firstName} ${username.lastName}`,
    }));
  },
};
