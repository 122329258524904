































































































































































import { PropType } from 'vue';
import { MessageEntity } from '@mentessa/types';
import { Vue } from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { ChangeRequest, DeleteRequest } from '@/store/messages';
import ImagePicker from '@/components/Inputs/ImagePicker.vue';

export default Vue.extend({
  name: 'FlipCard',
  props: {
    request: { type: Object as PropType<MessageEntity> },
  },
  components: {
    ImagePicker,
    UserCard,
  },
  data: function () {
    return {
      flipped: false,
      isEditMode: false,
      editedRequest: { attrs: {} } as MessageEntity,
      isRequestValid: true,
    };
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    isEditable() {
      return this.request.user.identity.id === this.me.identity.id;
    },
  },
  methods: {
    applyImage(imageLink) {
      this.editedRequest.attrs.image = imageLink;
    },
    openLink() {
      window.open(this.request.attrs.url);
    },
    cancelEditing() {
      this.resetRequest();
      this.isEditMode = false;
    },
    resetRequest() {
      this.editedRequest = {
        id: this.request.id,
        text: this.request.text,
        attrs: {
          description: this.request.attrs?.description,
          url: this.request.attrs?.url,
          image: this.request.attrs?.image,
        },
      };
    },
    async deleteRequest() {
      let isDeleteConfirmed = confirm('Delete this request?');
      if (isDeleteConfirmed) {
        await this.$store.dispatch(new DeleteRequest(this.request));
      }
    },
    async changeRequest() {
      const success = await this.$store.dispatch(new ChangeRequest({ request: this.request, dto: this.editedRequest }));
      if (success) {
        this.isEditMode = false;
      }
    },
  },
  mounted() {
    this.resetRequest();
  },
});
