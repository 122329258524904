




































import Vue, { PropType } from 'vue';
import { Slots, Weekdays } from '@mentessa/types';

export default Vue.extend({
  name: 'm-time-slot',
  props: {
    selectedSlots: { type: Object as PropType<Slots>, default: () => ({}) },
    available: { type: Boolean, default: false },
  },
  data: () => ({
    weekdays: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as Array<Weekdays>,
    slots: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
    enabledWeekdays: {} as Slots,
  }),
  computed: {
    isAvailable: {
      get(): boolean {
        return this.available;
      },
      set(newValue: boolean): void {
        this.$emit('onAvailableChanged', newValue);
      },
    },
  },
  methods: {
    toggleWeekday(weekday: Weekdays): void {
      let shouldCreate = true;
      if (Object.prototype.hasOwnProperty.call(this.selectedSlots, weekday)) {
        const copy = Object.assign({}, this.selectedSlots);
        delete copy[weekday];
        this.$emit('onSelectedSlotsChanged', copy);
        shouldCreate = false;
      }
      if (Object.prototype.hasOwnProperty.call(this.enabledWeekdays, weekday)) {
        this.$delete(this.enabledWeekdays, weekday as string);
        shouldCreate = false;
      }

      if (shouldCreate) {
        this.$set(this.enabledWeekdays, weekday as string, true);
      }
    },
    isWeekdayEnabled(weekday: Weekdays): boolean {
      return (
        Object.prototype.hasOwnProperty.call(this.selectedSlots, weekday) ||
        Object.prototype.hasOwnProperty.call(this.enabledWeekdays, weekday)
      );
    },
    isSlotSelected(weekday: Weekdays, slot: string): boolean {
      return this.selectedSlots[weekday]?.find((s) => s.time === slot) != null;
    },
    toggleSlot(weekday: Weekdays, slot: string): void {
      if (Object.prototype.hasOwnProperty.call(this.selectedSlots, weekday)) {
        const slotIndex = this.selectedSlots[weekday].findIndex((s) => s.time === slot);
        if (slotIndex !== -1) {
          const slots = [...this.selectedSlots[weekday]];
          slots.splice(slotIndex, 1);
          this.$emit('onSelectedSlotsChanged', { ...this.selectedSlots, [weekday]: slots });
        } else {
          const slots = [...this.selectedSlots[weekday], { available: true, time: slot }];
          slots.sort((l, r) => l.time.localeCompare(r.time));
          this.$emit('onSelectedSlotsChanged', { ...this.selectedSlots, [weekday]: slots });
        }
      } else {
        this.$emit('onSelectedSlotsChanged', { ...this.selectedSlots, [weekday]: [{ available: true, time: slot }] });
      }
    },
  },
});
