import { ActionTree } from 'vuex';
import { RootActions, RootMutations, RootState } from '@/store/types';
import Vue from 'vue';
import { GetMe, LoadPermissions } from '@/store/users';
import { LoadLocations, LoadTenant, LoadUsernames } from '@/store/tenant';
import { InitGeonames, LoadLanguages } from '@/store/utils';
import { pause, resume } from '@/router';
import { StartFetchMessages } from '@/store/messages';
import i18n from '@/plugins/i18n';
import { MessagesConfig } from '@mentessa/types';
import { LoadTranslations } from '@/store/ui';
import { LoadMatchingUsers } from '@/store/matching';

export const actions: ActionTree<RootState, RootState> = {
  async [RootActions.Initialize]({ commit, dispatch, state }, { loadData }) {
    pause();
    Vue.prototype.$log.debug('Initialize app');
    if (loadData) {
      await dispatch(new LoadTenant());
      await dispatch(new LoadLocations());
      await dispatch(new GetMe());
      await dispatch(new LoadUsernames());
      await dispatch(new LoadPermissions());

      const available = state.tenant.tenant.config.locales.available ?? ['en'];
      const browserLocale = navigator.language?.split('-')[0];
      const ifAvailable = (locale) => (locale && available.includes(locale) ? locale : null);
      i18n.locale =
        ifAvailable(state.users.me.attributes.lang) ??
        ifAvailable(browserLocale) ??
        ifAvailable(state.tenant.tenant.config.locales.default) ??
        'en';
      // const locale = user.locale || 'en';
      await dispatch(new LoadTranslations(i18n.locale));
      await dispatch(new LoadLanguages(i18n.locale));
      await dispatch(new InitGeonames(i18n.locale));
      await dispatch(new LoadMatchingUsers()); // ToDo: Find the other way to hide that button
      commit(RootMutations.SetDataLoaded, true);
      commit(RootMutations.Initialize);
      const messagesConfig: MessagesConfig = state.tenant.tenant.config.features.messages;
      if (messagesConfig?.fetchAuto ?? true) {
        await dispatch(new StartFetchMessages(messagesConfig?.fetchInterval));
      }
    } else {
      await dispatch(new GetMe());
      commit(RootMutations.Initialize);
    }
    resume();
  },
};
