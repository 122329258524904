









import { UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import { getFullName } from '@/utils/user';

export default Vue.extend({
  name: 'm-user-preview',
  props: {
    user: Object as PropType<UserEntity>,
    maxWidth: { type: String, default: '270px' },
    bold: { type: Boolean, default: false },
  },
  computed: {
    style() {
      return {
        '--preview-max-width': this.maxWidth,
      };
    },
    image() {
      return this.user?.identity.attributes.image ?? '/user-empty.png';
    },
    fullName() {
      return getFullName(this.user);
    },
  },
});
