


















































































import Dialog from '@/components/Dialog/Dialog.vue';
import Paragraph from '@/components/Typography/Paragraph.vue';
import Title_H5 from '@/components/Typography/Title_H5.vue';
import Button from '@/components/Buttons/Button.vue';
import FileDropzone from '@/components/FileDropzone/FileDropzone.vue';
import { ShowNotificationToastAction } from '@/store/ui';
import Vue from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import InviteTextareaInput from '@/components/Onboarding/InviteTextareaInput.vue';
import { InviteMembers } from '@/store/users';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('emails', {
  message: 'All records should be a valid email',
  validate: (records: string[]) => records.every((record) => email.validate(record)),
});

export default Vue.extend({
  name: 'modal-members',
  components: {
    Dialog,
    Paragraph,
    Title_H5,
    Button,
    FileDropzone,
    InviteTextareaInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emails: [],
      files: '',
      mode: 'email',
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', true);
    },
    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },
    updateUpload(files) {
      console.log(files);
    },
    toggleMode() {
      this.mode = this.mode === 'email' ? 'download' : 'email';
    },
    async handleMembersAdd() {
      if (await this.$store.dispatch(new InviteMembers(this.emails))) {
        await this.$store.dispatch(new ShowNotificationToastAction('Member(s) successfully added', 'success'));
        this.closeModal();
      }
    },
  },
  computed: {},
});
