








































import Vue from 'vue';
import { mapState } from 'vuex';
import { LoadTenant, TenantState } from '@/store/tenant';
import { TenantEntity } from '@mentessa/types';
import { InvitesState, LoadInvite, UseInvite } from '@/store/invites';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import NotificationSnackbar from '@/components/NotificationSnackbar.vue';
import i18n from '@/plugins/i18n';
import { LoadTranslations } from '@/store/ui';

setInteractionMode('aggressive');

extend('required', {
  ...required,
  message: 'The {_field_} can not be empty',
});
extend('email', {
  ...email,
  message: 'The {_field_} must be a valid email address',
});

export default Vue.extend({
  name: 'InviteApp',
  components: {
    ValidationObserver,
    ValidationProvider,
    NotificationSnackbar,
  },

  data: () => ({
    email: '',
    inviteSent: false,
  }),

  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<InvitesState>('invites', {
      invite: (state: InvitesState) => state.current,
    }),
  },
  watch: {
    async tenant(tenant: TenantEntity) {
      const theme = this.$vuetify.theme.themes.light;
      const colors = tenant.attributes.theme.colors;
      theme.primary = colors.primary;
      theme.secondary = colors.secondary;
      theme.accent = colors.accent;
      const title = tenant.attributes.theme.title;
      document.title = title ? title : 'Mentessa';
      if (tenant.attributes.theme.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = tenant.attributes.theme.favicon;
      }

      const available = tenant.config.locales.available ?? ['en'];
      const browserLocale = navigator.language?.split('-')[0];
      const ifAvailable = (locale) => (locale && available.includes(locale) ? locale : null);
      i18n.locale = ifAvailable(browserLocale) ?? ifAvailable(tenant.config.locales.default) ?? 'en';
      await this.$store.dispatch(new LoadTranslations(i18n.locale));
    },
  },
  methods: {
    async useInvite() {
      if (await this.$store.dispatch(new UseInvite(this.invite, this.email))) {
        this.inviteSent = true;
      }
    },
  },
  created() {
    const searchParams = new URLSearchParams(location.search);
    const guid = searchParams.get('key');
    this.$store.dispatch(new LoadTenant());
    this.$store.dispatch(new LoadInvite(guid));
  },
});
