




















import Vue from 'vue';

export default Vue.extend({
  name: 'Avatar',
  props: {
    userImage: {
      type: String,
      required: false,
    },
    fullName: {
      type: String,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitCLicked() {
      this.$emit('deleteImage', '');
    },
  },
  computed: {
    // getInitails() {
    // 	return this.fullName
    // 		.split(' ')
    // 		.map((n) => n[0])
    // 		.join('.');
    // },
  },
});
