import { Weekdays } from '@mentessa/types';
import { format, parse, parseISO } from 'date-fns';

const weekdaysMap: Record<Weekdays, number> = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };

export function getWeekdayNumber(weekday: Weekdays): number {
  return weekdaysMap[weekday];
}

export function getSlotNextDate(weekday: Weekdays, slot: string): Date {
  const dayNumber = getWeekdayNumber(weekday);
  const today = new Date();
  today.setDate(today.getDate() + ((8 + dayNumber - today.getDay()) % 7));
  const timeParts = slot.split(':');
  today.setHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);
  return today;
}

export function formatFullDate(date: Date): string {
  return format(date, 'EEE d MMMM yyyy h a');
}

export function formatFullDateFromString(dateStr: string): string {
  try {
    return format(parseISO(dateStr), 'EEE d MMMM yyyy h a');
  } catch (e) {
    console.error('Error while format date', e);
    return '';
  }
}

export function parseSlot(slot: string): Date {
  return parse(slot, 'HH:mm', new Date());
}

export function formatOneHourSlot(slot: string): string {
  const date = parseSlot(slot);
  return `${format(date, 'h aaaa')} - ${format(date.setHours(date.getHours() + 1), 'h aaaa')}`;
}
