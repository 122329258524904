












import Vue from 'vue';
import MMatchingDialogPure, { MatchingDialogStep } from './MatchingDialogPure.vue';
import { AddMatchings, CloseMatchingDialog, MatchingState, NotifyMatchedUsers } from '@/store/matching';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'm-matching-dialog',
  components: { MMatchingDialogPure },
  data: () => ({
    step: MatchingDialogStep.PickUsers,
  }),
  computed: {
    showDialog: {
      get() {
        return this.isVisible;
      },
      set(newValue) {
        if (!newValue) this.closeDialog();
      },
    },
    ...mapState<MatchingState>('matching', {
      isVisible: (state: MatchingState) => state.matchingDialog.visible,
      isLoading: (state: MatchingState) => state.matchingDialog.isLoading,
      matchings: (state: MatchingState) => state.matchings,
    }),
  },
  methods: {
    async doMatch(matchingPairs) {
      if (await this.$store.dispatch(new AddMatchings(matchingPairs))) {
        this.step = MatchingDialogStep.SendMessage;
      } else {
        console.error('Error while add matchings');
      }
    },
    async sendMessage(message) {
      await this.$store.dispatch(new NotifyMatchedUsers(message));
      await this.closeDialog();
      await this.clean();
    },
    async closeDialog() {
      await this.$store.dispatch(new CloseMatchingDialog());
      await this.clean();
    },
    async clean() {
      this.step = MatchingDialogStep.PickUsers;
    },
  },
});
