import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import icons from '@/icons';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#074BCB',
        secondary: '#75C7FB',
        text: '#1B1F26',
        lightbg: '#F1F1F1',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
    options: { customProperties: true },
  },
  icons: icons,
});
