import Vue from 'vue';
import Vuex from 'vuex';

import tenantModule from '@/store/tenant';
import usersModule from '@/store/users';
import utilsModule from '@/store/utils';
import uiModule from '@/store/ui';
import messagesModule from '@/store/messages';
import searchModule from '@/store/search';
import matchingModule from '@/store/matching';
import invitesModule from '@/store/invites';
import { RootState } from '@/store/types';
import { actions } from './actions';
import { mutations } from './mutations';

Vue.use(Vuex);

export const initialState: RootState = {
  initialized: false,
  dataLoaded: false,
};

export default new Vuex.Store<RootState>({
  state: initialState,
  actions,
  mutations,
  modules: {
    tenant: tenantModule,
    users: usersModule,
    utils: utilsModule,
    ui: uiModule,
    messages: messagesModule,
    search: searchModule,
    matching: matchingModule,
    invites: invitesModule,
  },
  strict: process.env.NODE_ENV !== 'production',
});
