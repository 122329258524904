



























































































import { Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { LoadRooms, MessagesState, SelectRoom, SendDirectMessage, SendMessage } from '@/store/messages';
import { MessageEntity, RoomEntity, UserEntity } from '@mentessa/types';
import { TenantState } from '@/store/tenant';
import MAutocompleteUsers from '@/components/Matching/Autocomplete/AutocompleteUsersStateful.vue';
import MAppointmentMessage from '@/components/Chat/AppointmentMessage.vue';
import MUserPreview from '@/components/Matching/UserPreview/UserPreviewPure.vue';
import { linkifyHtml } from '@/utils/linkify';

export default Vue.extend({
  components: { MUserPreview, MAppointmentMessage, MAutocompleteUsers },
  data: () => ({
    message: '',
    filterName: '',
    fakeRoom: undefined,
    isLoading: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
      users: (state: UsersState) => state.stream.users,
    }),
    ...mapState<TenantState>('tenant', {
      names: (state: TenantState) => state.usernames,
    }),
    ...mapState<MessagesState>('messages', {
      rooms: (state: MessagesState) => state.rooms,
      messages: (state: MessagesState) => state.messages,
      activeRoom: (state: MessagesState) => state.activeRoom,
      unreadMessageCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    room() {
      return this.activeRoom;
    },
    filteredRooms() {
      if (this.fakeRoom) {
        return [this.fakeRoom, ...this.rooms];
      }
      return this.rooms;
    },
  },
  watch: {
    async filterName(value) {
      if (!value) {
        this.fakeRoom = null;
        return;
      }
      const room = this.rooms.find((room) => this.getFullName(room.members[0].user) === value);
      if (room) {
        await this.selectRoom(room);
        return;
      }
      const user = this.users.find((user) => this.getFullName(user) === value);
      if (user) {
        if (!user.identity.attributes.image) user.identity.attributes.image = '/user-empty.png';
        this.fakeRoom = {
          members: [{ user }],
          id: 'fake',
          hasUnread: false,
        };
        await this.selectRoom(this.fakeRoom);
      } else {
        this.fakeRoom = null;
      }
    },
    async unreadMessageCount(count) {
      if (count === 0) {
        return;
      }
      await this.$store.dispatch(new LoadRooms());
      const updatedActiveRoom = this.rooms.find((room) => room.id === this.activeRoom?.id);
      if (updatedActiveRoom?.hasUnread) {
        await this.$store.dispatch(new SelectRoom(updatedActiveRoom));
      }
    },
  },
  methods: {
    async selectUser(user) {
      if (!user) {
        this.fakeRoom = null;
        return;
      }
      const room = this.rooms.find((room) => room.members[0].user.id === user.id);
      if (room) {
        await this.selectRoom(room);
        return;
      }
      if (user) {
        this.fakeRoom = {
          members: [{ user }],
          id: 'fake',
          hasUnread: false,
        };
        await this.selectRoom(this.fakeRoom);
      } else {
        this.fakeRoom = null;
      }
    },
    async sendMessage() {
      if (this.message) {
        try {
          this.isLoading = true;
          if (this.room.id === 'fake') {
            const newRoom = await this.$store.dispatch(
              new SendDirectMessage(this.room.members[0].user.id, this.message),
            );
            this.fakeRoom = null;
            const room = this.rooms.find((room) => room.id === newRoom.id);
            if (room) {
              await this.selectRoom(room);
            }
          } else {
            await this.$store.dispatch(new SendMessage(this.activeRoom.id, this.message));
            this.message = '';
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    isActive(room: RoomEntity): boolean {
      return room.id === this.room?.id;
    },
    async selectRoom(room: RoomEntity) {
      await this.$store.dispatch(new SelectRoom(room));
      if (this.$route.path !== `/inbox/${room.id}`) {
        await this.$router.push(`/inbox/${room.id}`);
      }
    },
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    getAvatar(user: UserEntity) {
      return user?.identity?.attributes.image ?? '/user-empty.png';
    },
    messageUser(message: MessageEntity) {
      if (message.userId === this.me.id) return this.me;
      else if (message.userId === this.room.members[0].user.id) return this.room.members[0].user;
      return undefined;
    },
    getAvatarForMessage(message: MessageEntity) {
      const user = this.messageUser(message);
      return this.getAvatar(user);
    },
    getFullNameForMessage(message: MessageEntity) {
      const user = this.messageUser(message);
      return user ? this.getFullName(user) : 'System';
    },
    isAppointment(message: MessageEntity) {
      return message.attrs.appointment != null;
    },
    isIncomingMessage(message: MessageEntity) {
      return message.userId !== this.me?.id;
    },
    isFirstInGroup(message: MessageEntity, index: number): boolean {
      return index <= 0 || this.messages[index - 1].userId != message.userId;
    },
    linkify: linkifyHtml,
  },
  async created() {
    await this.$store.dispatch(new LoadRooms());

    if (this.$route.params.roomId) {
      const room = this.rooms.find((r) => r.id === Number(this.$route.params.roomId));
      if (room) {
        await this.selectRoom(room);
      } else {
        await this.$router.push('/inbox');
      }
    }
  },
});
