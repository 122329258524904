




import Vue from 'vue';
import LogoPure from './LogoPure.vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-logo',
  components: { LogoPure },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenantLogo: (state: TenantState) => state.tenant.attributes.theme.image,
    }),
  },
});
