














import { Vue } from 'vue-property-decorator';
import UserStream from '@/components/UserStream.vue';
import Search from '@/components/Search.vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { MatchingState } from '@/store/matching';

export default Vue.extend({
  components: {
    Search,
    UserStream,
  },
  props: {
    matchingMode: { type: Boolean, default: false },
  },
  computed: {
    ...mapState<UsersState>('users', {
      streamUsers: (state: UsersState) => state.stream.users,
    }),
    ...mapState<MatchingState>('matching', {
      matchingUsers: (state: MatchingState) => state.users,
    }),
    users() {
      if (this.matchingMode) {
        console.log('Use matching users');
        return this.matchingUsers;
      } else {
        console.log('Use stream users', this.streamUsers);
        return this.streamUsers;
      }
    },
  },
});
