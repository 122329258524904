















































































































import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { Vue } from 'vue-property-decorator';
import { MessagesState } from '@/store/messages';
import { MatchingState, ShowMatchingDialog } from '@/store/matching';

export default Vue.extend({
  name: 'AppBar',
  computed: {
    ...mapState<UsersState>('users', {
      isLoading: (state: UsersState) => state.stream.isLoading,
      me: (state: UsersState) => state.me,
      canInvite: (state: UsersState) => state.permissions.canInvite,
    }),
    ...mapState<MessagesState>('messages', {
      unreadCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    ...mapState<MatchingState>('matching', {
      matchingEnabled: (state: MatchingState) => state.enabled,
      hasMatches: (state: MatchingState) => state.users?.length > 0,
    }),
    handshakeEmoji() {
      return require('@/assets/handshake-emoji.png');
    },
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout();
    },
    async showMatchingDialog() {
      await this.$store.dispatch(new ShowMatchingDialog());
    },
  },
});
