import { ActionTree } from 'vuex';
import { TenantActions, TenantMutations, TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { TenantEntity } from '@mentessa/types';
import { MatchingMutations } from '@/store/matching';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<TenantState, RootState> = {
  async [TenantActions.Load]({ commit }) {
    const result = await api.get('tenant');
    const tenant: TenantEntity = result?.data;
    commit(TenantMutations.SetTenant, tenant);
    if (tenant?.config.features.matching?.enabled) {
      commit(`matching/${MatchingMutations.SetMatchingEnabled}`, true, { root: true });
    }
    console.log(`Tenant "${tenant.attributes.name}" loaded`);
  },

  async [TenantActions.LoadLocations]({ commit }) {
    const result = await api.get('tenant/locations');
    const locations = result?.data;
    commit(TenantMutations.SetLocations, locations);
    console.log(`Locations loaded`);
  },

  async [TenantActions.LoadUsernames]({ commit }) {
    const result = await api.get('tenant/usernames');
    const usernames = result?.data;
    commit(TenantMutations.SetUsernames, usernames);
    console.log(`Usernames loaded`);
  },

  async [TenantActions.CreateTenant]({ dispatch }, { options }) {
    try {
      const response = await api.post('tenants/new', options);
      return response.status === 201;
    } catch (e) {
      console.log('Error while create new tenant', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [TenantActions.UpdateTenant]({ dispatch }, { tenant, updates }) {
    try {
      const response = await api.put(`tenants/${tenant.id}`, updates);
      return response.status === 200;
    } catch (e) {
      console.log('Error while update tenant', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
  async [TenantActions.UploadTenantImage]({ dispatch }, { image }) {
    try {
      const formData = new FormData();
      formData.append('file', image);
      const result = await api.post('public/image/tenant', formData);
      if (result.status === 201) return result.data;
    } catch (e) {
      console.log('Error while update tenant', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return undefined;
  },
};
