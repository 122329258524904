import { MessageAttributes, MessageEntity, RoomEntity } from '@mentessa/types';
import { Action } from '@/store/types';
import { UserLoadingPageMeta } from '@/store/users';

export const moduleName = 'messages';

export interface PaginationMetadata {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemCount?: number;
  itemsPerPage?: number;
}

export interface MessagesLoadingPage {
  items: MessageEntity[];
  meta: PaginationMetadata;
}

export interface MessagesStorage {
  items: MessageEntity[];
  pagination: UserLoadingPageMeta;
  isLoading: boolean;
}

export interface RequestsStorage extends MessagesStorage {
  onlyMy: boolean;
  hashtags: string[];
}

export interface MessagesState {
  rooms: RoomEntity[];
  messages: MessageEntity[];
  lastFetchTime?: Date;
  fetchTimer?: number;
  unreadMessageCount: number;
  activeRoom?: RoomEntity;
  requests: RequestsStorage;
}

export interface CreateRequestOptions {
  text: string;
  attrs?: MessageAttributes;
}

export enum MessagesActions {
  LoadRooms = '[Messages] Load Rooms',
  LoadMessages = '[Messages] Load Messages',
  SendMessage = '[Messages] Send Message',
  SendDirectMessage = '[Messages] Send Direct Message',
  StartFetchMessages = '[Messages] Start Fetch Message',
  StopFetchMessages = '[Messages] Stop Fetch Message',
  CheckNewMessages = '[Messages] Check New Messages',
  ReadWholeRoom = '[Messages] Read Whole Room',
  SelectRoom = '[Messages] Select Room',
  // LoadRequests = '[Messages] Load Requests',
  LoadNextRequestsPage = '[Messages] Load Next Requests Page',
  CreateRequest = '[Messages] Create Request',
  DeleteRequest = '[Messages] Delete Request',
  ChangeRequest = '[Messages] Change Request',
  FilterMyRequests = '[Messages] Filter My Requests',
  FilterRequestsByHashtags = '[Messages] Filter Requests By hashtags',
  UploadRequestImage = '[Messages] Upload Request Image',
}

export enum MessagesMutations {
  SetRooms = '[Messages] Set Rooms',
  SetMessages = '[Messages] Set Messages',
  AppendMessage = '[Messages] Append Message',
  SetTimer = '[Messages] Set Timer',
  SetLastFetchTime = '[Messages] Set Last Fetch Time',
  SetUnreadMessageCount = '[Messages] Set Unread Message Count',
  SetActiveRoom = '[Messages] Set Active Room',
  ReadActiveRoom = '[Messages] Read Active Room',
  SetRequestsLoadingFlag = '[Messages] Set Requests Loading Flag',
  AddRequestsPage = '[Messages] Add Requests Page',
  AppendRequest = '[Messages] Append Request',
  RemoveRequest = '[Messages] Remove Request',
  SetOnlyMyRequests = '[Messages] Set Only My Requests',
  SetHashtagsForRequests = '[Messages] Set Hashtags For Requests',
  ClearRequests = '[Messages] Clear Requests',
  SetRequest = '[Messages] Set Request',
}

export class LoadRooms implements Action {
  type = `${moduleName}/${MessagesActions.LoadRooms}`;
}

export class LoadMessages implements Action {
  type = `${moduleName}/${MessagesActions.LoadMessages}`;

  constructor(public roomId: number) {
    this.roomId = roomId;
  }
}

export class SendMessage implements Action {
  type = `${moduleName}/${MessagesActions.SendMessage}`;

  constructor(public roomId: number, public text: string) {
    this.roomId = roomId;
    this.text = text;
  }
}

export class SendDirectMessage implements Action {
  type = `${moduleName}/${MessagesActions.SendDirectMessage}`;

  constructor(public toUserId: number, public text: string) {
    this.toUserId = toUserId;
    this.text = text;
  }
}

export class StartFetchMessages implements Action {
  type = `${moduleName}/${MessagesActions.StartFetchMessages}`;

  constructor(public interval: number = 5 * 60 * 1000) {
    this.interval = interval;
  }
}

export class StopFetchMessages implements Action {
  type = `${moduleName}/${MessagesActions.StopFetchMessages}`;
}

export class CheckNewMessages implements Action {
  type = `${moduleName}/${MessagesActions.CheckNewMessages}`;
}

export class SelectRoom implements Action {
  type = `${moduleName}/${MessagesActions.SelectRoom}`;

  constructor(public room: RoomEntity) {
    this.room = room;
  }
}

export class ReadWholeRoom implements Action {
  type = `${moduleName}/${MessagesActions.ReadWholeRoom}`;

  constructor(public room: RoomEntity) {
    this.room = room;
  }
}

export class LoadNextRequestsPage implements Action {
  type = `${moduleName}/${MessagesActions.LoadNextRequestsPage}`;
}

export class CreateRequest implements Action {
  type = `${moduleName}/${MessagesActions.CreateRequest}`;
  constructor(public options: CreateRequestOptions) {
    this.options = options;
  }
}

export class ChangeRequest implements Action {
  type = `${moduleName}/${MessagesActions.ChangeRequest}`;
  request: MessageEntity;
  dto: Partial<MessageEntity>;
  constructor(options: { request: MessageEntity; dto: Partial<MessageEntity> }) {
    this.request = options.request;
    this.dto = options.dto;
  }
}

export class DeleteRequest implements Action {
  type = `${moduleName}/${MessagesActions.DeleteRequest}`;
  constructor(public request: MessageEntity) {
    this.request = request;
  }
}

export class FilterMyRequests implements Action {
  type = `${moduleName}/${MessagesActions.FilterMyRequests}`;
  constructor(public enabled: boolean) {
    this.enabled = enabled;
  }
}

export class FilterRequestsByHashtags implements Action {
  type = `${moduleName}/${MessagesActions.FilterRequestsByHashtags}`;
  constructor(public tags: string[]) {
    this.tags = tags;
  }
}
