




















































import Vue from 'vue';

export default Vue.extend({
  name: 'm-logo-pure',
  props: {
    image: String,
  },
});
