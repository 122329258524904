import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { UtilsActions, UtilsMutations, UtilsState } from '@/store/utils/types';
import { LocalizedLanguageNames } from '@cospired/i18n-iso-languages';
import Geonames from 'geonames.js';

export const actions: ActionTree<UtilsState, RootState> = {
  async [UtilsActions.LoadLanguages]({ commit }, { locale }) {
    let data = await import(`@cospired/i18n-iso-languages/langs/${locale}.json`);
    if (data == null) {
      data = await import(`@cospired/i18n-iso-languages/langs/en.json`);
    }
    const langNames: LocalizedLanguageNames = data.languages;
    commit(UtilsMutations.SetLanguages, langNames);
    console.log(`Languages for ${locale} loaded`);
  },

  async [UtilsActions.InitGeonames]({ commit }, { locale }) {
    const geonames = Geonames({
      username: 'tihmenev',
      lan: 'de',
      encoding: 'JSON',
    });
    commit(UtilsMutations.SetGeonames, geonames);
    console.log(`Init geonames for ${locale}`);
  },
};
