












































































































































































































































import SectionSettingsGroup from '@/components/SectionSettingsGroup/SectionSettingsGroup.vue';
import Paragraph from '@/components/Typography/Paragraph.vue';
import Button from '@/components/Buttons/Button.vue';
import InputField from '@/components/InputField/InputField.vue';
import Textarea from '@/components/Textarea/Textarea.vue';
import TextareaTags from '@/components/Textarea/TextareaTags.vue';
import SwitchToggle from '@/components/Switch/SwitchToggle.vue';
import Dropdown from '@/components/Dropdowns/DropdownList.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import TimeSlot from '@/components/TimeSlots/TimeSlot.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'TabPersonal',
  props: {},
  components: {
    SectionSettingsGroup,
    Paragraph,
    Button,
    InputField,
    Textarea,
    TextareaTags,
    SwitchToggle,
    Dropdown,
    Avatar,
    TimeSlot,
  },
  data: () => ({
    communityRulesToggle: true,
    communityRulesCollection: [
      {
        value: 'Don’t be rude',
        key: 'rule1',
      },
      {
        value: 'Don’t spam',
        key: 'rule2',
      },
    ],
    userImage: '',
    fullName: 'Philipp Klaus',
    email: 'philipp@mvst.co',
    company: 'MVST',
    position: 'Head of Design',
    location: 'Munich',
    languages: 'English, German',
    tagsExpertise: ['Engineering', 'Database', 'Data Science', 'Sales', 'Backend Development', 'Frontend'],
    tagsInterests: ['Engineering', 'Database', 'Data Science', 'Sales', 'Backend Development', 'Frontend'],
    aboutMe:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    automaticMatchToggle: true,
    automaticMatchingFrequencySelected: { text: 'Weekly', value: 'weekly-option' },
    automaticMatchingFrequency: [
      {
        text: 'Daily',
        value: 'daily-option',
      },
      {
        text: 'Weekly',
        value: 'weekly-option',
      },
      {
        text: 'Monthly',
        value: 'monthly-option',
      },
      {
        text: 'Yearly',
        value: 'yearly-option',
      },
    ],
    mentoringToggle: false,
  }),
  computed: {
    classes() {
      return {};
    },
  },
  methods: {
    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },
    addMore(category) {
      const categoryLength = this[category].length;
      this[category].push({ value: '', key: `${category}${categoryLength}` });
    },
    toggleFileInput() {
      this.$refs.file.click();
    },
    updateUserImage(e) {
      const file = e.target.files[0];
      this.userImage = URL.createObjectURL(file);
    },
    clearAvatar() {
      this.userImage = '';
    },
    tagsUpdated(data) {
      this[`${data.category}`] = data.value;
    },
    save() {
      console.log('Save');
    },
    upgrade() {
      console.log('upgrade');
    },
    deleteProfile() {
      console.log('deleteProfile');
    },
  },
});
