import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { UtilsState } from '@/store/utils';
import { MessagesState } from '@/store/messages';
import { SearchState } from '@/store/search';
import { MatchingState } from '@/store/matching';
import { InvitesState } from '@/store/invites';

export interface RootState {
  initialized: boolean;
  dataLoaded: boolean;
  users?: UsersState;
  tenant?: TenantState;
  utils?: UtilsState;
  messages?: MessagesState;
  search?: SearchState;
  matching?: MatchingState;
  invites?: InvitesState;
}

export interface Action {
  readonly type: string;
}

export enum RootActions {
  Initialize = '[Root] Initialize',
}

export enum RootMutations {
  Initialize = '[Root] Initialize',
  SetDataLoaded = '[Root] Set Data Loaded',
  Logout = '[Root] Logout',
}

export class Initialize implements Action {
  type = `${RootActions.Initialize}`;

  constructor(public loadData: boolean) {
    this.loadData = loadData;
  }
}
