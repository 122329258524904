import { render, staticRenderFns } from "./InputFile.vue?vue&type=template&id=1f50b6f0&scoped=true&v-if=true&"
import script from "./InputFile.vue?vue&type=script&lang=ts&"
export * from "./InputFile.vue?vue&type=script&lang=ts&"
import style0 from "./InputFile.vue?vue&type=style&index=0&id=1f50b6f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f50b6f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VFileInput,VIcon})
