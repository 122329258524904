



















import Vue from 'vue';

export default Vue.extend({
  name: 'Tabs',
  props: {
    tabsNav: {
      type: Array,
      required: true,
    },
    border: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    classes() {
      return {
        tabs: true,
        'tabs--without-border': this.border === false,
      };
    },
    filteredTabs() {
      return this.tabsNav?.filter((tab) => tab.enabled) ?? [];
    },
  },
});
