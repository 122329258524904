


















import Vue from 'vue';

export default Vue.extend({
  name: 'Button',
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
  computed: {
    classes() {
      return {
        btn: true,
        'btn--s': this.size === 's',
        'btn--m': this.size === 'm',
        'btn--l': this.size === 'l',
        'btn--xl': this.size === 'xl',
        'btn--secondary': this.type === 'secondary',
        'btn--tertiary': this.type === 'tertiary',
        'btn--stroke': this.type === 'stroke',
        'btn--link': this.type === 'link',
      };
    },
  },
});
