import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { InvitesState } from '@/store/invites/types';

export const initialState: InvitesState = {
  all: [],
  current: undefined,
};

export const invitesModule: Module<InvitesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default invitesModule;

export * from './types';
