









































































import Pagination from '@/components/Pagination/Pagination.vue';
import Tags from '@/components/Tags/Tags.vue';
import ModalDeleteMember from '@/components/Modals/ModalDeleteMember.vue';
import { mapState } from 'vuex';
import Vue from 'vue';
import { getFullName } from '@mentessa/frontend/src/utils/user';
import { DeleteInvite, DisableInvite, InviteEntity, InvitesState, LoadAllInvites } from '@/store/invites';
import MNewInviteForm from '@/components/Modals/MNewInviteForm.vue';
import Dialog from '@/components/Dialog/Dialog.vue';

export default Vue.extend({
  name: 'm-invitation-links',
  props: {},
  components: {
    MNewInviteForm,
    Pagination,
    Tags,
    ModalDeleteMember,
    Dialog,
  },
  data: () => ({
    searchMember: '',
    isModalOpen: false,
    isModalDeleteOpen: false,
    currentPage: 1,
    isToastVisible: false,
    visibleDropdownId: undefined,
    checkedItems: [],
    userToDelete: undefined,
    invitesPerPage: 10,
    newInviteDialog: false,
  }),
  computed: {
    ...mapState<InvitesState>('invites', {
      allInvites: (state: InvitesState): InviteEntity[] => state.all,
    }),

    totalPages() {
      return Math.ceil(this.allInvites.length / this.invitesPerPage);
    },
    invites() {
      return (
        this.allInvites?.slice((this.currentPage - 1) * this.invitesPerPage, this.currentPage * this.invitesPerPage) ??
        []
      );
    },
  },
  methods: {
    async deleteInvite(invite: InviteEntity) {
      await this.$store.dispatch(new DeleteInvite(invite));
    },
    async disableInvite(invite: InviteEntity) {
      await this.$store.dispatch(new DisableInvite(invite));
    },
    getFullName: getFullName,
    copyToClipboard(invite: InviteEntity) {
      const link = `${window.origin}/invite?key=${invite.guid}`;
      navigator.clipboard.writeText(link);
    },
  },
  mounted() {
    this.$store.dispatch(new LoadAllInvites());
  },
});
