import { Action } from '@/store/types';

export const moduleName = 'ui';

export interface NotificationDialog {
  message: string;
  variant?: string;
  buttonText?: string;
}

export interface NotificationSnackbar {
  message: string;
}

export interface NotificationToast {
  message: string;
  state?: string;
}

export interface UIState {
  notificationDialog: NotificationDialog;
  notificationSnackbar: NotificationSnackbar;
  notificationToast: NotificationToast;
}

export enum UIActions {
  ShowNotificationDialog = '[UI] Show Notification Dialog',
  ShowNotificationSnackbar = '[UI] Show Notification Snackbar',
  ShowNotificationToast = '[UI] Show Notification Toast',
  LoadTranslations = '[UI] Load Translations',
}

export enum UIMutations {
  SetNotificationDialog = '[UI] Set Notification Dialog',
  SetNotificationSnackbar = '[UI] Set Notification Snackbar',
  SetNotificationToast = '[UI] Set Notification Toast',
  SetTranslations = '[UI] Set Translations',
}

export class ShowNotificationDialogAction implements Action {
  constructor(public message: string, public variant: string, public buttonText: string) {
    this.message = message;
    this.variant = variant;
    this.buttonText = buttonText;
  }

  type = `${moduleName}/${UIActions.ShowNotificationDialog}`;
}

export class ShowNotificationSnackbarAction implements Action {
  constructor(public message: string) {
    this.message = message;
  }

  type = `${moduleName}/${UIActions.ShowNotificationSnackbar}`;
}

export class ShowNotificationToastAction implements Action {
  constructor(public message: string, public state: string) {
    this.message = message;
    this.state = state;
  }

  type = `${moduleName}/${UIActions.ShowNotificationToast}`;
}

export class LoadTranslations implements Action {
  constructor(public lang: string) {
    this.lang = lang;
  }

  type = `${moduleName}/${UIActions.LoadTranslations}`;
}
