











































import { UserCardConfig, UserCardField, UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import TextArea from '@/components/Inputs/TextArea.vue';
import TextField from '@/components/Inputs/TextField.vue';
import Language from '@/components/Inputs/Language.vue';
import SelectMultiple from '@/components/Inputs/SelectMultiple.vue';
import SelectOne from '@/components/Inputs/SelectOne.vue';
import ComboboxMultiple from '@/components/Inputs/ComboboxMultiple.vue';
import ImagePicker from '@/components/Inputs/ImagePicker.vue';
import Switcher from '@/components/Inputs/Switcher.vue';
import Location from '@/components/Inputs/Location.vue';
import { email, max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { SaveUser, SetUserField, UpdateUserField, UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import MTimeSlots from '@/components/TimeSlots/TimeSlots.vue';

// Note: eager is better here, but with it it we have valueChanged event before invalid state changed to valid for initially invalid fields.
setInteractionMode('aggressive');

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

extend('max', {
  ...max,
  message: '{_field_} must be less than 50 characters',
});

extend('email', {
  ...email,
  message: '{_field_} should be a valid email',
});

export default Vue.extend({
  name: 'ProfileEdit',
  props: {
    user: Object as PropType<UserEntity>,
    cardConfig: Object as PropType<UserCardConfig>,
  },
  components: {
    MTimeSlots,
    TextArea,
    TextField,
    ImagePicker,
    SelectMultiple,
    SelectOne,
    ComboboxMultiple,
    Switcher,
    Language,
    Location,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    fields(): Array<UserCardField> {
      return this.cardConfig?.fields;
    },
    ...mapState<UsersState>('users', {
      isComplete: (state: UsersState) => state.me.complete,
    }),
    ...mapGetters('tenant', {
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    isTimeSlotsEnabled() {
      return this.isFeatureEnabled('timeSlots');
    },
    classes() {
      return {
        'm-user-profile-desktop': this.$vuetify.breakpoint.smAndUp,
        'm-user-profile-mobile': !this.$vuetify.breakpoint.smAndUp,
      };
    },
  },
  watch: {
    //
  },
  data: () => ({
    values: {},
    timeSlots: {
      available: undefined,
      slots: {},
    },
  }),
  methods: {
    resolvePath(object, path: string, defaultValue = undefined) {
      return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
    },
    async valueChanged(path: string, value, valid: boolean) {
      if (valid) {
        console.log('ProfileEdit::value changed', path, value, valid);
        if (Array.isArray(value)) {
          value.forEach((v, i, s) => {
            if (v.name == null) {
              s[i] = { name: v };
            }
          });
        }
        if (this.isComplete) {
          await this.$store.dispatch(new UpdateUserField(path, value));
          await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('user.profileSaved')));
        } else {
          await this.$store.dispatch(new SetUserField(path, value));
        }
      }
    },
    loadValues() {
      const values = {};
      this.fields.forEach((field) => {
        // console.log(`${field.path} == `, this.resolvePath(this.user, field.path));
        values[field.name] = this.resolvePath(this.user, field.path);
      });
      if (this.isTimeSlotsEnabled) {
        this.timeSlots.available = this.resolvePath(this.user, 'attributes.timeSlots.available');
        this.timeSlots.slots = this.resolvePath(this.user, 'attributes.timeSlots.slots');
      }
      if (this.values) {
        Object.assign(this.values, values);
      } else {
        this.values = values;
      }
    },
    async save() {
      await this.$store.dispatch(new SaveUser());
      await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('user.profileSaved')));
      await this.$router.push('/');
    },
    async changeTimeSlotsAvailable(isAvailable: boolean) {
      this.timeSlots.available = isAvailable;
      await this.valueChanged('attributes.timeSlots.available', isAvailable, true);
    },
    async changeTimeSlotsSlots(slots) {
      this.timeSlots.slots = slots;
      await this.valueChanged('attributes.timeSlots.slots', slots, true);
    },
  },
  created() {
    this.loadValues();
  },
});
