



























import InviteTextareaInput from '@/components/Onboarding/InviteTextareaInput.vue';
import Vue, { PropType } from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});
extend('emails', {
  message: 'All records should be a valid email',
  validate: (records: string[]) => records.every((record) => email.validate(record)),
});

export default Vue.extend({
  name: 'FormStep3',
  components: { InviteTextareaInput, ValidationObserver, ValidationProvider },
  props: {
    members: { type: Array as PropType<string[]>, default: () => [] },
  },
  data: () => ({
    valid: true,
    isFileUploadMode: false,
  }),
  computed: {
    localEmails: {
      get() {
        return this.members;
      },
      set(value) {
        this.$emit('onMembersChanged', value);
      },
    },
  },
  methods: {
    submitStep() {
      this.$emit('onSubmit');
    },
    doLater() {
      this.$emit('onSubmit');
    },
  },
});
