






















































































































































































































import SectionSettingsGroup from '@/components/SectionSettingsGroup/SectionSettingsGroup.vue';
import Paragraph from '@/components/Typography/Paragraph.vue';
import Button from '@/components/Buttons/Button.vue';
import InputField from '@/components/InputField/InputField.vue';
import InputFile from '@/components/InputFile/InputFile.vue';
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
import SwitchToggle from '@/components/Switch/SwitchToggle.vue';
import Dropdown from '@/components/Dropdowns/DropdownList.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState, UpdateTenant, UploadTenantImage } from '@/store/tenant';
import { TenantEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'TabCommunity',
  props: {},
  components: {
    SectionSettingsGroup,
    Paragraph,
    Button,
    InputField,
    InputFile,
    ColorPicker,
    SwitchToggle,
    Dropdown,
  },
  data: () => ({
    logo: '',
    closedCommunity: true,
    automaticMatchingToggle: true,
    automaticMatchingCollection: [
      {
        value: '',
        key: 'automaticMatching1',
      },
    ],
    premiumMembershipToggle: true,
    monthlyPrice: '',
    yearlyPrice: '',
    memberApplyToggle: true,
    questionCollection: [
      {
        value: 'How did you hear about us?',
        key: 'question1',
      },
      {
        value: 'How can you contribute to the community?',
        key: 'question2',
      },
    ],
    communityRulesToggle: true,
    communityRulesCollection: [
      {
        value: 'Don’t be rude',
        key: 'rule1',
      },
      {
        value: 'Don’t spam',
        key: 'rule2',
      },
    ],
    updates: {
      attributes: {
        theme: {
          title: undefined,
          tagLine: undefined,
          colors: {
            primary: undefined,
          },
          image: undefined,
        },
      },
    },
  }),
  computed: {
    classes() {
      return {};
    },
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    tenantName: {
      get() {
        return this.tenant?.attributes.theme.title;
      },
      set(value) {
        this.assignPath(this.updates, 'attributes.theme.title', value);
      },
    },
    tenantTagLine: {
      get() {
        return this.tenant?.attributes.theme.tagLine;
      },
      set(value) {
        this.assignPath(this.updates, 'attributes.theme.tagLine', value);
      },
    },
    tenantImage: {
      get() {
        return this.tenant?.attributes.theme.image;
      },
      set(value) {
        this.assignPath(this.updates, 'attributes.theme.image', value);
      },
    },
    tenantPrimaryColor: {
      get() {
        return this.updates?.attributes?.theme.colors.primary ?? this.tenant?.attributes.theme.colors.primary;
      },
      set(value) {
        this.assignPath(this.updates, 'attributes.theme.colors.primary', value);
      },
    },
    tenantSecondaryColor: {
      get() {
        return this.tenant?.attributes.theme.colors.secondary;
      },
      set(value) {
        this.assignPath(this.updates, 'attributes.theme.colors.secondary', value);
      },
    },
  },
  methods: {
    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },

    addMore(category) {
      const categoryLength = this[category].length;
      this[category].push({ value: '', key: `${category}${categoryLength}` });
    },

    async save() {
      if (this.updates?.attributes?.theme?.image) {
        const imagePath = await this.$store.dispatch(new UploadTenantImage(this.updates.attributes.theme.image));
        if (!imagePath) return;
        this.updates.attributes.theme.image = `/api${imagePath}`;
      }
      await this.$store.dispatch(new UpdateTenant(this.tenant, this.updates));
    },

    assignPath(object, path: string, value = undefined) {
      let o = object;
      const splitPath = path.split('.');
      for (let i = 0; i < splitPath.length - 1; ++i) {
        if (!Object.prototype.hasOwnProperty.call(o, splitPath[i])) {
          o[splitPath[i]] = {};
        }
        o = o[splitPath[i]];
      }
      o[splitPath[splitPath.length - 1]] = value;
    },
  },
});
