import { ActionTree } from 'vuex';
import { ClearStream, LoadNextStreamPage, UsersActions, UsersMutations, UsersState } from './types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { UserEntity } from '@mentessa/types';
import { ShowNotificationDialogAction, ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<UsersState, RootState> = {
  async [UsersActions.LoadNextStreamPage]({ commit, state, getters, dispatch }) {
    const shouldLoad = getters.isNewPageAvailable;
    const {
      seed,
      stream: {
        pagination: { currentPage },
        filters,
        onlyFavorites,
        onlyMentors,
      },
    } = state;
    if (shouldLoad) {
      await commit(UsersMutations.SetStreamLoadingFlag, true);
      const params = {
        page: currentPage + 1,
        seed,
        ...filters,
      };
      if (onlyFavorites) {
        params['favorites'] = true;
      }
      if (onlyMentors) {
        params['mentors'] = true;
      }
      try {
        const response = await api.get('users', { params });
        const data = response?.data;
        // ToDo: Maybe should be fixed on backend side
        data.meta.currentPage = Number(data.meta.currentPage);
        commit(UsersMutations.AddStreamPage, data);
      } catch (e) {
        console.error('Error while loading users.', e);
        commit(UsersMutations.ClearStream, true);
        await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
      await commit(UsersMutations.SetStreamLoadingFlag, false);
    }
  },

  async [UsersActions.LoadMembersPage]({ commit, state, dispatch }, { page, filter }) {
    const {
      members: { pagination },
    } = state;
    const shouldLoad = pagination.currentPage === 0 || page <= pagination.totalPages;
    if (shouldLoad) {
      await commit(UsersMutations.SetMembersLoadingFlag, true);
      const params = { page, filter };
      try {
        const response = await api.get('manage/users', { params });
        const data = response?.data;
        // ToDo: Maybe should be fixed on backend side
        data.meta.currentPage = Number(data.meta.currentPage);
        commit(UsersMutations.SetMembers, data);
      } catch (e) {
        console.error('Error while loading members.', e);
        commit(UsersMutations.ClearStream, true);
        await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      } finally {
        await commit(UsersMutations.SetMembersLoadingFlag, false);
      }
    }
  },

  async [UsersActions.GetMe]({ commit }) {
    const response = await api.get('auth/me');
    const user: UserEntity = response?.data;
    console.log(`Hi ${user.identity.attributes.firstName} ${user.identity.attributes.lastName} (${user.id})`);
    commit(UsersMutations.SetMe, user);
    return user;
  },
  async [UsersActions.GetUserDetails]({ commit, state }, { userId }) {
    let user = state.stream.users.find((user) => user.id === userId);
    if (!user) {
      const response = await api.get(`users/${userId}`);
      user = response?.data;
    }
    await commit(UsersMutations.SetDetailedUser, user);
    return user;
  },
  async [UsersActions.UpdateUserField]({ commit, state }, { field, value }) {
    commit(UsersMutations.SetUserField, { field, value });
    await api.put('auth/me', state.me);
    return state.me;
  },
  async [UsersActions.SetField]({ commit, state }, { field, value }) {
    commit(UsersMutations.SetUserField, { field, value });
    return state.me;
  },
  async [UsersActions.Save]({ state, commit }) {
    commit(UsersMutations.SetUserField, { field: 'complete', value: true });
    await api.put('auth/me', state.me);
    return state.me;
  },

  async [UsersActions.ApplyFilter]({ commit, dispatch }, { filters }) {
    await dispatch(new ClearStream(), { root: true });
    commit(UsersMutations.SetStreamFilters, filters);
    await dispatch(new LoadNextStreamPage(), { root: true });
  },

  async [UsersActions.ClearStream]({ commit }) {
    commit(UsersMutations.ClearStream);
  },
  // eslint-disable-next-line
  async [UsersActions.InviteUser]({ commit, dispatch }, { email, isMentor, isAdmin }) {
    try {
      await api.post('users/invite', { email, isMentor, isAdmin });
      await dispatch(
        new ShowNotificationDialogAction(
          i18n.t('notifications.inviteSent').toString(),
          '',
          i18n.t('notifications.ok').toString(),
        ),
        { root: true },
      );
    } catch (e) {
      if (e.response.status !== 409) {
        await dispatch(
          new ShowNotificationDialogAction(
            i18n.t('notifications.inviteError').toString(),
            '',
            i18n.t('notifications.ok').toString(),
          ),
          { root: true },
        );
      } else {
        await dispatch(
          new ShowNotificationDialogAction(
            i18n.t('notifications.inviteExists').toString(),
            '',
            i18n.t('notifications.ok').toString(),
          ),
          { root: true },
        );
      }
    }
  },
  // eslint-disable-next-line
  async [UsersActions.UploadImage]({ commit }, { image }) {
    const formData = new FormData();
    formData.append('file', image);
    const result = await api.post('public/image/user', formData);
    return result?.data;
  },

  async [UsersActions.LoadPermissions]({ commit }) {
    const result = await api.get('auth/permissions');
    commit(UsersMutations.SetPermissions, result.data);
    return result.data;
  },

  async [UsersActions.SetFavoriteStatus]({ commit, dispatch }, { target, enabled }) {
    if (target.isFavorite === enabled) {
      return;
    }
    try {
      await api.post(`users/${target.id}/favorite`, { enabled });
      commit(UsersMutations.SetFavoriteStatus, { target, status: enabled });
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteSuccess').toString()), {
        root: true,
      });
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return target;
  },
  async [UsersActions.ShowOnlyFavorites]({ commit, state, dispatch }, { enabled }) {
    if (state.stream.onlyFavorites === enabled) {
      return;
    }
    commit(UsersMutations.SetOnlyFavorites, enabled);
    await dispatch(new ClearStream(), { root: true });
    await dispatch(new LoadNextStreamPage(), { root: true });
  },

  async [UsersActions.ShowOnlyMentors]({ commit, state, dispatch }, { enabled }) {
    if (state.stream.onlyMentors === enabled) {
      return;
    }
    commit(UsersMutations.SetOnlyMentors, enabled);
    await dispatch(new ClearStream(), { root: true });
    await dispatch(new LoadNextStreamPage(), { root: true });
  },

  async [UsersActions.ForceSeed]({ commit }, { seed }) {
    commit(UsersMutations.SetSeed, seed);
  },

  async [UsersActions.AddAdminRole]({ commit, dispatch }, { user }) {
    try {
      const result = await api.post(`manage/admins/`, { id: user.id });
      if (result?.status === 201) {
        commit(UsersMutations.SetAdminFlag, { user, isAdmin: true });
        return true;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.RevokeAdminRole]({ commit, dispatch }, { user }) {
    try {
      const result = await api.delete(`manage/admins/${user.id}`);
      if ([200, 204].includes(result?.status)) {
        commit(UsersMutations.SetAdminFlag, { user, isAdmin: false });
        return true;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.DeleteUser]({ commit, dispatch }, { user }) {
    try {
      const result = await api.delete(`users/${user.id}`);
      if ([200, 204].includes(result?.status)) {
        commit(UsersMutations.RemoveUser, user);
        return true;
      }
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [UsersActions.InviteMembers]({ dispatch }, { emails }) {
    try {
      const result = await api.post(`manage/invite`, { emails });
      return result?.status === 201;
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.setFavoriteError').toString()), {
        root: true,
      });
    }
    return false;
  },
};
