import { MutationTree } from 'vuex';
import { InviteEntity, InvitesMutations, InvitesState } from '@/store/invites/types';

export const mutations: MutationTree<InvitesState> = {
  [InvitesMutations.SetAllInvites](state: InvitesState, invites: InviteEntity[]) {
    state.all = invites;
  },
  [InvitesMutations.SetCurrentInvite](state: InvitesState, invite: InviteEntity) {
    state.current = invite;
  },
};
