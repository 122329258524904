<template>
  <div class="m-loader-circle-wrap" v-if="isLoading">
    <svg
      class="m-loader-circle m-loader-circle-animation"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3768 8.28638C22.4926 8.09579 24.1506 6.31743 24.1506 4.15178C24.1506 1.98612 22.4926 0.207774 20.3768 0.0171816V0.00195312C31.2484 0.203195 39.9995 9.08009 39.9995 20.0012C39.9995 30.9223 31.2484 39.7992 20.3768 40.0004L20.3768 39.9833C20.2526 39.9945 20.1267 40.0003 19.9996 40.0003C17.707 40.0003 15.8486 38.1416 15.8486 35.8488C15.8486 33.5559 17.707 31.6972 19.9996 31.6972C20.1267 31.6972 20.2526 31.703 20.3768 31.7142V31.695C26.6629 31.4958 31.6977 26.3366 31.6977 20.0013C31.6977 13.666 26.6629 8.50675 20.3768 8.30756V8.28638Z"
        fill="url(#paint0_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6227 31.7134C17.5068 31.9039 15.8486 33.6823 15.8486 35.848C15.8486 38.0138 17.5068 39.7922 19.6227 39.9827L19.6227 39.998C8.7511 39.7968 -5.62128e-08 30.9199 1.85305e-06 19.9988C3.76229e-06 9.07774 8.75111 0.200849 19.6227 -0.000387062L19.6227 0.0161382C19.7469 0.00496115 19.8726 -0.000749438 19.9997 -0.000749427C22.2922 -0.000749238 24.1506 1.85794 24.1506 4.15076C24.1506 6.44357 22.2922 8.30226 19.9997 8.30226C19.8726 8.30226 19.7469 8.29655 19.6227 8.28537L19.6227 8.30508C13.3365 8.50422 8.30168 13.6635 8.30168 19.9988C8.30168 26.3342 13.3365 31.4934 19.6227 31.6925L19.6227 31.7134Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="30.1882"
          y1="40.0004"
          x2="30.1882"
          y2="0.00264364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--v-primary-base)" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="9.8112"
          y1="-0.000754596"
          x2="9.81119"
          y2="39.9974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="var(--v-primary-base)" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    isLoading: Boolean,
  },
  name: 'Loader',
});
</script>

<style scoped>
.m-loader-circle-wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
}

.m-loader-circle {
  width: 80px;
  height: 80px;
  position: relative;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.m-loader-circle-wrap.fade-enter-active,
.m-loader-circle-wrap.fade-leave-active {
  transition: opacity 0.3s;
}

.m-loader-circle-animation {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(0.4);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}
</style>
