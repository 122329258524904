






























































import { mapState } from 'vuex';
import { MessagesState } from '@/store/messages';
import { Vue } from 'vue-property-decorator';
import { ShowMatchingDialog } from '@/store/matching';

export default Vue.extend({
  name: 'BottomNavigation',
  computed: {
    ...mapState<MessagesState>('messages', {
      unreadCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    showFilters() {
      return this.$route.name === 'Community';
    },
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout();
    },
    async showMatchingDialog() {
      await this.$store.dispatch(new ShowMatchingDialog());
    },
  },
});
