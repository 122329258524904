var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"m-dashboard-table",attrs:{"headers":_vm.headers,"items":_vm.matchings,"items-per-page":10,"calculate-widths":"","custom-sort":_vm.customSort,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.user1",fn:function(ref){
var item = ref.item;
return [_c('m-user-preview',{attrs:{"user":_vm.extractMember(0)(item)}})]}},{key:"item.user2",fn:function(ref){
var item = ref.item;
return [_c('m-user-preview',{attrs:{"user":_vm.extractMember(1)(item)}})]}},{key:"item.initiator",fn:function(ref){
var item = ref.item;
return [_c('m-user-preview',{attrs:{"user":item.initiator}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseAndFormatDate(item.createdAt))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }