import { render, staticRenderFns } from "./Language.vue?vue&type=template&id=4607e555&scoped=true&"
import script from "./Language.vue?vue&type=script&lang=ts&"
export * from "./Language.vue?vue&type=script&lang=ts&"
import style0 from "./Language.vue?vue&type=style&index=0&id=4607e555&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4607e555",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VAutocomplete,VCheckbox,VChip})
