






































import Vue from 'vue';
import StepIndicator from '@/components/Onboarding/StepIndicator.vue';
import FormStep1 from '@/components/Onboarding/FormStep1.vue';
import FormStep2 from '@/components/Onboarding/FormStep2.vue';
import FormStep3 from '@/components/Onboarding/FormStep3.vue';
import { CreateTenant } from '@/store/tenant';

export default Vue.extend({
  name: 'Onboarding',
  components: { FormStep1, FormStep2, FormStep3, StepIndicator },
  data: () => ({
    step: 0,
    name: '',
    domain: '',
    tagLine: '',
    members: [],
  }),
  computed: {
    layoutPreview() {
      switch (this.step) {
        case 0:
          return require('@/assets/onboarding/layout-preview-step-1.svg');
        case 1:
          return require('@/assets/onboarding/layout-preview-step-2.svg');
        case 2:
          return require('@/assets/onboarding/layout-preview-step-3.svg');
      }
      return undefined;
    },
  },
  methods: {
    goToPreviousStep() {
      if (this.step > 0) this.step--;
    },
    getStepTitle() {
      switch (this.step) {
        case 0:
          return this.$t('onboarding.step1.title');
        case 1:
          return this.$t('onboarding.step2.title');
        case 2:
          return this.$t('onboarding.step3.title');
      }
    },
    getStepDescription() {
      switch (this.step) {
        case 0:
          return this.$t('onboarding.step1.description');
        case 1:
          return this.$t('onboarding.step2.description');
        case 2:
          return this.$t('onboarding.step3.description');
      }
    },
    async onSubmit() {
      if (this.step < 2) {
        ++this.step;
      } else {
        const success = await this.$store.dispatch(
          new CreateTenant({
            name: this.name,
            domain: this.domain,
            tagLine: this.tagLine,
            members: this.members,
          }),
        );
        if (success) {
          const splittedHost = window.location.host.split('.');
          splittedHost[0] = this.domain;
          window.location.host = splittedHost.join('.');
        }
      }
    },
  },
});
