import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { Matching, MatchingActions, MatchingMutations, MatchingState } from './types';
import { api } from '@/store/api';
import { UserEntity } from '@mentessa/types';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';
import { UsersMutations } from '@/store/users';
import { getSlotNextDate } from '@/utils/weekdays';

export const actions: ActionTree<MatchingState, RootState> = {
  async [MatchingActions.AddMatchings]({ commit, dispatch }, { pairs }) {
    commit(MatchingMutations.SetMatchingDialogLoading, true);
    try {
      const preparedPairs = pairs.map((pair) => ({
        matchingUsers: [{ user: { id: pair.left.id } }, { user: { id: pair.right.id } }],
      }));
      const response = await api.post('matching', preparedPairs);
      commit(MatchingMutations.SetMatchings, response.data as Matching[]);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('matching.created').toString()), {
        root: true,
      });
      return true;
    } catch (e) {
      console.error('Error while add matching', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      commit(MatchingMutations.SetMatchingDialogLoading, false);
    }
    return false;
  },
  async [MatchingActions.ShowMatchingDialog]({ commit }) {
    commit(MatchingMutations.SetMatchingDialogVisible, true);
  },
  async [MatchingActions.CloseMatchingDialog]({ commit }) {
    commit(MatchingMutations.SetMatchingDialogVisible, false);
  },
  async [MatchingActions.LoadMatchingUsers]({ commit }) {
    await commit(MatchingMutations.SetMatchingLoading, true);
    try {
      const response = await api.get('users/matched');
      const users: UserEntity[] = response?.data?.items;
      commit(MatchingMutations.SetMatchingIsLoaded, true);
      if (users) commit(MatchingMutations.SetMatchingUsers, users);
    } catch (e) {
      console.log('Error while load matching users', e);
    } finally {
      await commit(MatchingMutations.SetMatchingLoading, false);
    }
  },

  async [MatchingActions.NotifyMatchedUsers]({ state, dispatch }, { message }) {
    try {
      const ids = state.matchings.map((matching) => matching.id);
      await api.post('matching/notify', { message, ids });
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('matching.notifySent').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while notify about matched', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      //
    }
  },
  async [MatchingActions.LoadAllMatchings]({ dispatch, commit }) {
    try {
      commit(MatchingMutations.SetAllMatchingsIsLoading, true);
      const response = await api.get('matching');
      commit(MatchingMutations.SetAllMatchings, response.data);
      return response.data;
    } catch (e) {
      console.error('Error while load matchings', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    } finally {
      commit(MatchingMutations.SetAllMatchingsIsLoading, false);
    }
  },
  async [MatchingActions.BookSession]({ dispatch, commit }, { user, weekday, slot, message }) {
    let success = false;
    try {
      const response = await api.post('matching/bookSlot', {
        user: { id: user.id },
        weekday,
        slot,
        message,
        date: getSlotNextDate(weekday, slot.time),
      });
      success = response.status === 201;
    } catch (e) {
      console.error('Error while load matchings', e);
      success = false;
    }
    if (success) {
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('timeSlots.notifyBooked').toString()), {
        root: true,
      });
      commit(
        `users/${UsersMutations.SetSlotAvailable}`,
        {
          user,
          weekday,
          slot,
          isAvailable: false,
        },
        { root: true },
      );
    } else {
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return success;
  },
};
