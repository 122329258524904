import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { SearchActions, SearchState } from './types';
import { api } from '@/store/api';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<SearchState, RootState> = {
  async [SearchActions.SearchUsers](_, { token }) {
    console.log(`Search for user. Token: ${token}`, token);
    return [];
  },

  async [SearchActions.CheckDomainNameAvailability]({ dispatch }, { name }) {
    console.log(`Check Domain availability. Name: ${name}`);
    try {
      const response = await api.post('tenants/check_domain', { name });
      const data = response?.data;
      return data?.result ?? false;
    } catch (e) {
      console.error('Error while CheckDomainNameAvailability.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
