
















































import Vue from 'vue';
import { CheckDomainNameAvailability } from '@/store/search';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default Vue.extend({
  name: 'FormStep1',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    name: String,
    domain: String,
  },
  data: () => ({
    domainLoading: false,
  }),
  computed: {
    localName: {
      get() {
        return this.name;
      },
      set(value: string) {
        this.$emit('onNameChanged', value.trim());
      },
    },
    localDomain: {
      get() {
        return this.domain;
      },
      set(value: string) {
        this.$emit('onDomainChanged', value.trim());
      },
    },
  },
  watch: {
    domain(): void {
      this.domainLoading = true;
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
    async checkDomain(name: string): Promise<{ valid: boolean }> {
      this.domainLoading = true;
      try {
        const isAvailable = await this.$store.dispatch(new CheckDomainNameAvailability(name));
        return {
          valid: isAvailable,
        };
      } catch (e) {
        return {
          valid: false,
        };
      } finally {
        this.domainLoading = false;
      }
    },
  },
  mounted() {
    extend('uniq-domain', {
      message: 'That URL already taken',
      validate: (value) => {
        return this.checkDomain(value);
      },
    });
  },
});
