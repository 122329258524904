import { render, staticRenderFns } from "./NotificationSnackbar.vue?vue&type=template&id=a7fae502&scoped=true&"
import script from "./NotificationSnackbar.vue?vue&type=script&lang=ts&"
export * from "./NotificationSnackbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7fae502",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VIcon,VSnackbar})
