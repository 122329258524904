import { TenantEntity } from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'tenant';

export interface TenantState {
  tenant?: TenantEntity;
  tags?: { id: number; name: string }[];
  locations: { name: string }[];
  usernames: { id?: number; firstName: string; lastName: string; image: string }[];
}

export interface CreateTenantOptions {
  name: string;
  domain: string;
  tagLine: string;
  members?: string[];
}

export enum TenantActions {
  Load = '[Tenant] Load',
  LoadLocations = '[Tenant] Load Locations',
  LoadUsernames = '[Tenant] Load Usernames',
  CreateTenant = '[Tenant] Create Tenant',
  UpdateTenant = '[Tenant] Update Tenant',
  UploadTenantImage = '[Tenant] Upload Tenant Image',
}

export enum TenantMutations {
  SetTenant = '[Tenant] Set Tenant',
  LoadSuccess = '[Tenant] Load Success',
  LoadFailed = '[Tenant] Load Failed',
  SetLocations = '[Tenant] Set Locations',
  SetUsernames = '[Tenant] Set Usernames',
}

export class LoadTenant implements Action {
  type = `${moduleName}/${TenantActions.Load}`;
}

export class LoadLocations implements Action {
  type = `${moduleName}/${TenantActions.LoadLocations}`;
}

export class LoadUsernames implements Action {
  type = `${moduleName}/${TenantActions.LoadUsernames}`;
}

export class CreateTenant implements Action {
  type = `${moduleName}/${TenantActions.CreateTenant}`;

  constructor(public options: CreateTenantOptions) {
    this.options = options;
  }
}

export class UpdateTenant implements Action {
  type = `${moduleName}/${TenantActions.UpdateTenant}`;

  constructor(public tenant: TenantEntity, public updates: Partial<TenantEntity>) {
    this.tenant = tenant;
    this.updates = updates;
  }
}

export class UploadTenantImage implements Action {
  type = `${moduleName}/${TenantActions.UploadTenantImage}`;

  constructor(public image: File) {
    this.image = image;
  }
}
