










import { Sketch } from 'vue-color';
import Vue from 'vue';

export default Vue.extend({
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '#000',
    },
    height: {
      type: String,
      required: false,
      default: 'normal',
    },
  },
  data: () => ({
    // colors: {
    //   hex: '',
    //   // hex8: '#194D33A8',
    //   // hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
    //   // hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
    //   //rgba: { r: 25, g: 77, b: 51, a: 1 },
    //   a: 1,
    // },
    isPickerVisible: false,
  }),
  computed: {
    classes() {
      return {
        colorpicker: true,
        'colorpicker--show': this.isPickerVisible,
        'colorpicker--height-small': this.height === 'small',
      };
    },
    localColor: {
      get() {
        return this.value;
      },
      set(value: { hex: string }) {
        this.$emit('input', value.hex);
      },
    },
  },
  methods: {
    toggleVisibility() {
      this.isPickerVisible = !this.isPickerVisible;
    },
    handleClose() {
      this.isPickerVisible = false;
    },
  },
});
