import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { MatchingState } from './types';

export const initialState: MatchingState = {
  matchingDialog: {
    visible: false,
    isLoading: false,
  },
  users: [],
  enabled: false,
  isLoading: false,
  matchings: [],
  isLoaded: false,
  all: {
    matchings: [],
    isLoading: false,
  },
};

export const matchingModule: Module<MatchingState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default matchingModule;

export * from './types';
