












import Vue from 'vue';

export default Vue.extend({
  name: 'SwitchToggle',
  props: {
    label: {
      type: String,
      required: false,
    },
    state: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    onFieldChange: {
      type: Function,
      required: false,
    },
  },
  components: {},
  data: () => ({
    switch1: true,
    switch2: true,
  }),
  methods: {
    onChange(value) {
      this.$emit('onFieldChange', { name: this.fieldName, value });
    },
  },
  computed: {
    classes() {
      return {
        switch: true,
        'switch--error': this.state === 'error',
      };
    },
  },
});
