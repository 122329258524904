














import Vue from 'vue';

export default Vue.extend({
  name: 'Tags',
  props: {
    text: {
      type: String,
      required: true,
    },
    isClosable: {
      type: Boolean,
      required: false,
    },
    isOutlined: {
      type: Boolean,
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data: () => ({
    isTagVisible: true,
  }),
  methods: {
    onClick() {
      this.$emit('closeTag', true);
      // this.isVisible = false;
      // this.$emit('onClick');
    },
  },
  computed: {
    classes() {
      return {
        tag: true,
        'tag--outlined': this.isOutlined,
      };
    },
  },
});
