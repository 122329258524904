import { Module } from 'vuex';
import { RootState } from '@/store/types';
// import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { UIState } from '@/store/ui/types';

export const initialState: UIState = {
  notificationDialog: {
    message: '',
    variant: '',
    buttonText: '',
  },
  notificationSnackbar: {
    message: '',
  },
  notificationToast: {
    message: '',
    state: '',
  },
};

export const uiModule: Module<UIState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};

export default uiModule;

export * from './types';
