import { render, staticRenderFns } from "./Title_H5.vue?vue&type=template&id=504bec34&scoped=true&"
import script from "./Title_H5.vue?vue&type=script&lang=ts&"
export * from "./Title_H5.vue?vue&type=script&lang=ts&"
import style0 from "./Title_H5.vue?vue&type=style&index=0&id=504bec34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504bec34",
  null
  
)

export default component.exports