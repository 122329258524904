










import Filters from '@/components/Filters.vue';
import Logo from '@/components/Logo';
import Vue from 'vue';

export default Vue.extend({
  name: 'Drawer',
  components: { Logo, Filters },
  props: {
    miniVariant: Boolean,
  },
});
