import { FilterConfig, UserEntity } from '@mentessa/types';

interface Action {
  readonly type: string;
}

export const moduleName = 'users';

export interface UserLoadingPageMeta {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemCount?: number;
  itemsPerPage?: number;
}

export interface UsersLoadingPage {
  items: UserEntity[];
  meta: UserLoadingPageMeta;
}

export interface FilterWithValue extends FilterConfig {
  value: { id?: number; name: string }[];
}

export type Filters = { [filter: string]: FilterWithValue };

export interface UserStorage {
  users: UserEntity[];
  pagination: UserLoadingPageMeta;
  filters: Filters;
  isLoading: boolean;
  onlyFavorites: boolean;
  onlyMentors: boolean;
}

export interface UsersState {
  seed: number; // Use it to randomise result for entire session
  stream: UserStorage;
  search: UserStorage;
  members: UserStorage;
  me?: UserEntity;
  detailedUser?: UserEntity;
  permissions: {
    canInvite: boolean;
  };
}

export enum UsersActions {
  LoadNextStreamPage = '[Users] Load Next Stream Page',
  LoadNextSearchPage = '[Users] Load Next Search Page',
  LoadMembersPage = '[Users] Load Members Page',
  GetMe = '[Users] Get Me',
  GetUserDetails = '[Users] Load Details',
  UpdateUserField = '[Users] Update field',
  ApplyFilter = '[Users] Apply Filter',
  ClearStream = '[Users] Clear Stream',
  InviteUser = '[Users] Invite User',
  UploadImage = '[Users] Upload Image',
  SetField = '[Users] Set Field',
  Save = '[Users] Save',
  LoadPermissions = '[Users] Load Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  ShowOnlyFavorites = '[Users] Show Only Favorites',
  ShowOnlyMentors = '[Users] Show Only Mentors',
  ForceSeed = '[Users] Force Seed',
  AddAdminRole = '[Users] Add Admin Role',
  RevokeAdminRole = '[Users] Revoke Admin Role',
  DeleteUser = '[Users] Delete user',
  InviteMembers = '[Users] Invite Members',
}

export enum UsersMutations {
  SetStreamLoadingFlag = '[Users] Set Stream Loading Flag',
  SetSearchLoadingFlag = '[Users] Set Search Loading Flag',
  SetMembersLoadingFlag = '[Users] Set Members Loading Flag',
  SetMembersCurrentPage = '[Users] Set Members Current page',
  AddStreamPage = '[Users] Add Stream Page',
  AddSearchPage = '[Users] Add Search Page',
  SetMembers = '[Users] Set Members',
  AddUsersToStream = '[Users] Add Users To Stream',
  SetMe = '[Users] Set me',
  SetDetailedUser = '[Users] Set Detailed User',
  SetUserField = '[Users] Set User Field',
  SetStreamFilters = '[Users] Set Stream Filters',
  ClearStream = '[Users] Clear Stream',
  SetPermissions = '[Users] Set Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  SetOnlyFavorites = '[Users] Set Only Favorites',
  SetOnlyMentors = '[Users] Set Only Mentors',
  SetSeed = '[Users] Set Seed',
  SetSlotAvailable = '[Users] Set Slot available',
  SetAdminFlag = '[Users] Set Admin Flag',
  RemoveUser = '[Users] Remove User',
}

export class LoadNextStreamPage implements Action {
  type = `${moduleName}/${UsersActions.LoadNextStreamPage}`;
}

export class LoadNextSearchPage implements Action {
  type = `${moduleName}/${UsersActions.LoadNextSearchPage}`;
}

export class LoadMembersPage implements Action {
  type = `${moduleName}/${UsersActions.LoadMembersPage}`;

  constructor(public page: number, public filter?: string) {
    this.page = page;
    this.filter = filter;
  }
}

export class GetMe implements Action {
  type = `${moduleName}/${UsersActions.GetMe}`;
}

export class GetUserDetails implements Action {
  constructor(public userId: number) {
    this.userId = userId;
  }

  type = `${moduleName}/${UsersActions.GetUserDetails}`;
}

export class UpdateUserField implements Action {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(public field: string, public value) {
    this.field = field;
    this.value = value;
  }

  type = `${moduleName}/${UsersActions.UpdateUserField}`;
}

export class ApplyFilter implements Action {
  constructor(public filters: Filters) {
    this.filters = filters;
  }

  type = `${moduleName}/${UsersActions.ApplyFilter}`;
}

export class ClearStream implements Action {
  type = `${moduleName}/${UsersActions.ClearStream}`;
}

export class InviteUser implements Action {
  constructor(public email: string, public isMentor: boolean = false, public isAdmin: boolean = false) {
    this.email = email;
    this.isMentor = isMentor;
    this.isAdmin = isAdmin;
  }

  type = `${moduleName}/${UsersActions.InviteUser}`;
}

export class UploadImage implements Action {
  constructor(public image: File) {
    this.image = image;
  }

  type = `${moduleName}/${UsersActions.UploadImage}`;
}

export class SetUserField implements Action {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(public field: string, public value) {
    this.field = field;
    this.value = value;
  }

  type = `${moduleName}/${UsersActions.SetField}`;
}

export class SaveUser implements Action {
  type = `${moduleName}/${UsersActions.Save}`;
}

export class LoadPermissions implements Action {
  type = `${moduleName}/${UsersActions.LoadPermissions}`;
}

export class SetFavoriteStatus implements Action {
  type = `${moduleName}/${UsersActions.SetFavoriteStatus}`;

  constructor(public target: UserEntity, public enabled: boolean) {
    this.target = target;
    this.enabled = enabled;
  }
}

export class ShowOnlyFavorites implements Action {
  type = `${moduleName}/${UsersActions.ShowOnlyFavorites}`;

  constructor(public enabled: boolean) {
    this.enabled = enabled;
  }
}

export class ShowOnlyMentors implements Action {
  type = `${moduleName}/${UsersActions.ShowOnlyMentors}`;

  constructor(public enabled: boolean) {
    this.enabled = enabled;
  }
}

export class ForceSeed implements Action {
  type = `${moduleName}/${UsersActions.ForceSeed}`;

  constructor(public seed: number) {
    this.seed = seed;
  }
}

export class AddAdminRole implements Action {
  type = `${moduleName}/${UsersActions.AddAdminRole}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class RevokeAdminRole implements Action {
  type = `${moduleName}/${UsersActions.RevokeAdminRole}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class DeleteUser implements Action {
  type = `${moduleName}/${UsersActions.DeleteUser}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class InviteMembers implements Action {
  type = `${moduleName}/${UsersActions.InviteMembers}`;

  constructor(public emails: string[]) {
    this.emails = emails;
  }
}
