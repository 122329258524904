interface Action {
  readonly type: string;
}

export const moduleName = 'search';

export interface SearchState {
  isLoading: boolean;
}

export enum SearchActions {
  SearchUsers = '[Search] SearchUsers',
  CheckDomainNameAvailability = '[Search] CheckDomainNameAvailability',
}

export enum UtilsMutations {}

export class SearchUsers implements Action {
  constructor(public token: string) {
    this.token = token;
  }
  type = `${moduleName}/${SearchActions.SearchUsers}`;
}

export class CheckDomainNameAvailability implements Action {
  constructor(public name: string) {
    this.name = name;
  }
  type = `${moduleName}/${SearchActions.CheckDomainNameAvailability}`;
}
