import { MutationTree } from 'vuex';
import { RootMutations, RootState } from '@/store/types';

export const mutations: MutationTree<RootState> = {
  [RootMutations.Initialize](state: RootState) {
    state.initialized = true;
  },
  [RootMutations.SetDataLoaded](state: RootState, loaded) {
    state.dataLoaded = loaded;
  },

  [RootMutations.Logout](state: RootState) {
    state.initialized = false;
  },
};
