



































import Paragraph from '../Typography/Paragraph.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'InputField',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      required: false,
    },
    iconString: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    state: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    onFieldChange: {
      type: Function,
      required: false,
    },
  },
  components: { Paragraph },
  data: () => ({}),
  methods: {
    onChange(value) {
      // ToDo: Refactor this
      this.$emit('onFieldRealChange', { name: this.fieldName, value });
    },
    onInput(value) {
      this.$emit('onFieldChange', { name: this.fieldName, value });
    },
  },
  computed: {
    classes() {
      return {
        'form-element': true,
        'form-element--error': this.state === 'error',
        'form-element--warning': this.state === 'warning',
        'form-element--success': this.state === 'success',
        'form-element--disabled': this.state === 'disabled',
        'form-element--show-state': this.state !== 'none',
        'form-element--left-icon': this.iconPosition === 'left',
        'form-element--right-icon': this.iconPosition === 'right',
      };
    },
  },
});
