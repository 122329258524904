




































import Vue, { PropType } from 'vue';
import { google, ics, office365, outlook, yahoo } from 'calendar-link';
import { CalendarEvent } from 'calendar-link/dist/interfaces';
import { MessageEntity, RoomMemberEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import { formatFullDateFromString } from '@/utils/weekdays';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { linkifyHtml } from '@/utils/linkify';

export default Vue.extend({
  name: 'm-appointment-message',
  props: {
    message: { type: Object as PropType<MessageEntity>, default: () => ({}) },
    members: { type: Array as PropType<RoomMemberEntity[]>, default: () => [] },
  },
  computed: {
    title(): string {
      return this.$t('timeSlots.appointmentRequests', {
        date: formatFullDateFromString(this.message.attrs.appointment.date),
      });
    },
    ...mapState<TenantState>('tenant', {
      tenantTitle: (state: TenantState) => state.tenant.attributes.theme.title,
    }),
    hasText() {
      return this.message?.text?.length > 0;
    },
  },
  methods: {
    calendarLink(target) {
      const event: CalendarEvent = {
        title: `${this.tenantTitle} - 1:1 with ${getFullName(this.members[0].user)}`,
        description: this.message.text,
        start: this.message.attrs.appointment.date,
        duration: [1, 'hour'],
        guests: [this.members[0].user.identity.attributes.email],
      };
      return target === 'google'
        ? google(event)
        : target === 'outlook'
        ? outlook(event)
        : target === 'office365'
        ? office365(event)
        : target === 'yahoo'
        ? yahoo(event)
        : target === 'ics'
        ? ics(event)
        : undefined;
    },
    getFullName: getFullName,
    linkify: linkifyHtml,
  },
});
