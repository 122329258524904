import { RouteConfig } from 'vue-router';
import Community from '../views/Community.vue';
import Profile from '../views/Profile.vue';
import Chat from '../views/Chat.vue';
import Admin from '../views/Admin.vue';
import Requests from '../views/Requests.vue';
import { toBoolean } from '@/utils/convert';
import Dashboard from '../views/Dashboard.vue';
import Settings from '@/views/Settings.vue';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Community',
    component: Community,
    props: (route) => ({
      matchingMode: toBoolean(route.query.matching),
    }),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/inbox',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/inbox/:roomId',
    name: 'ChatWithUser',
    component: Chat,
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: Community,
    props: (route) => ({
      matchingMode: toBoolean(route.query.matching),
    }),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/requests',
    name: 'Requests',
    component: Requests,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
];
