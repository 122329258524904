import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SearchState } from './types';

export const initialState: SearchState = {
  isLoading: false,
};

export const searchModule: Module<SearchState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default searchModule;

export * from './types';
