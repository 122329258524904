<template v-if="true">
  <div class="section-settings-group">
    <div class="section__entry mb-2 mb-md-0">
      <Title_H5 v-if="this.title" :text="this.title" weight="bold" />

      <Paragraph v-if="this.subtitle" :text="this.subtitle" size="small" weight="medium" />
    </div>

    <div class="section__box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Paragraph from '../Typography/Paragraph.vue';
import Title_H5 from '../Typography/Title_H5.vue';

export default {
  name: 'SectionSettingsGroup',
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: false,
    },
  },
  components: {
    Paragraph,
    Title_H5,
  },
  // data: () => ({}),
};
</script>

<style lang="scss">
.section-settings-group {
  display: flex;
  justify-content: space-between;
  padding: 42px 0;
  border-bottom: 1px solid $color-coolgrey-300;

  .section__entry {
    // width: 40%;
    width: 230px;

    p {
      color: $color-coolgrey-500;
    }

    h5 {
      margin-bottom: 8px;
    }
  }

  .section__box {
    width: 60%;
    width: 588px;
    padding: 24px 40px 40px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: $breakpoint-mobile) {
    display: block;

    padding: 24px 24px 32px;

    .section__box {
      background: none;
      box-shadow: none;
      width: 100%;
      padding: 0;
    }
  }
}
</style>
