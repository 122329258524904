

































































































































import Pagination from '@/components/Pagination/Pagination.vue';
import Tags from '@/components/Tags/Tags.vue';
import InputField from '@/components/InputField/InputField.vue';
import Button from '@/components/Buttons/Button.vue';
import ModalMember from '@/components/Modals/ModalMember.vue';
import ModalDeleteMember from '@/components/Modals/ModalDeleteMember.vue';
import { mapState } from 'vuex';
import { AddAdminRole, InviteUser, LoadMembersPage, RevokeAdminRole, UsersState } from '@/store/users';
import Vue from 'vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@mentessa/frontend/src/utils/user';

export default Vue.extend({
  name: 'TabMembers',
  props: {},
  components: {
    Pagination,
    Tags,
    InputField,
    Button,
    ModalMember,
    ModalDeleteMember,
  },
  data: () => ({
    searchMember: '',
    isModalOpen: false,
    isModalDeleteOpen: false,
    page: 1,
    isToastVisible: false,
    visibleDropdownId: undefined,
    checkedItems: [],
    userToDelete: undefined,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      users: (state: UsersState) => state.members.users,
      isLoading: (state: UsersState) => state.members.isLoading,
      currentPage: (state: UsersState) => state.members.pagination.currentPage,
      totalPages: (state: UsersState) => state.members.pagination.totalPages,
    }),
    isLoading() {
      if (this.matchingMode) return this.isMatchingLoading;
      else return this.isStreamLoading;
    },
  },
  methods: {
    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },
    async doSearch(filter: string) {
      this.searchMember = filter;
      await this.setPage(1);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.isModalDeleteOpen = false;
    },
    openDeleteModal(user: UserEntity) {
      this.userToDelete = user;
      this.isModalDeleteOpen = true;
    },
    toggleDropdown(id) {
      if (id === this.visibleDropdownId) {
        this.visibleDropdownId = '';
      } else {
        this.visibleDropdownId = id;
      }
    },
    hideDropdown(item) {
      if (!item.target.offsetParent.className.includes('member-dropdown')) {
        this.visibleDropdownId = '';
      }
    },
    toggleAll(value) {
      this.checkedItems = [];
      if (value === true) {
        this.users.forEach((item) => {
          this.checkedItems.push(item.id);
        });
      } else {
        this.checkedItems = [];
      }
    },
    // remove async await
    handleDeleteSuccess() {
      this.isToastVisible = true;
    },
    closeToast() {
      this.isToastVisible = false;
    },

    async setPage(page: number) {
      this.page = page;
      await this.$store.dispatch(new LoadMembersPage(page, this.searchMember));
    },
    async makeAdmin(user: UserEntity) {
      await this.$store.dispatch(new AddAdminRole(user));
    },
    async revokeAdmin(user: UserEntity) {
      await this.$store.dispatch(new RevokeAdminRole(user));
    },
    async makePremium(user: UserEntity) {
      console.log('Make premium', user.id);
    },
    async resendInvite(user: UserEntity) {
      await this.$store.dispatch(new InviteUser(user.identity.attributes.email));
    },
    getFullName: getFullName,
  },
  mounted() {
    this.setPage(1);
  },
});
