import Vue from 'vue';
import VueRouter from 'vue-router';
import { app, AppType } from '@/config';
import { routes as newCommunityRoutes } from './new';
import { routes as useCommunityRoutes } from './use';
import store from '../store';

Vue.use(VueRouter);

const routes = app.type === AppType.NewCommunity ? newCommunityRoutes : useCommunityRoutes;
Vue.prototype.$log.debug(`Load routes table for [${AppType[app.type]}] ${app.community}`);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let pausedResolve = null;
let pausedPromise = Promise.resolve();

router.beforeEach(async (to, from, next) => {
  await pausedPromise;
  if (app.type === AppType.UseCommunity) {
    const user = store.state.users.me;
    if (!user.complete) {
      if (to.path != '/profile') {
        return next('/profile');
      }
    }
  }
  next();
});

export function pause(): void {
  if (!pausedResolve) {
    console.log('Pause routing');
    pausedPromise = new Promise((resolve) => (pausedResolve = resolve));
  }
}

export function resume(): void {
  if (pausedResolve) {
    console.log('Resume routing');
    pausedResolve();
    pausedResolve = null;
  }
}

pause();

export default router;
