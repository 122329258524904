import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { ShowNotificationSnackbarAction, UIActions, UIMutations, UIState } from './types';
import { api } from '@/store/api';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<UIState, RootState> = {
  async [UIActions.ShowNotificationDialog]({ commit }, { message, variant, buttonText }) {
    commit(UIMutations.SetNotificationDialog, { message, variant, buttonText });
  },
  async [UIActions.ShowNotificationSnackbar]({ commit }, { message }) {
    commit(UIMutations.SetNotificationSnackbar, { message });
  },
  async [UIActions.ShowNotificationToast]({ commit }, { message, state }) {
    commit(UIMutations.SetNotificationToast, { message, state });
  },
  async [UIActions.LoadTranslations]({ commit, dispatch }, { lang }) {
    try {
      const response = await api.get(`i18n/${lang}`);
      const data = response?.data;
      commit(UIMutations.SetTranslations, { lang, content: data });
      return data?.result ?? false;
    } catch (e) {
      console.error('Error while LoadTranslations.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },
};
