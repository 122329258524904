














































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { ApplyFilter, ShowOnlyFavorites, ShowOnlyMentors, UsersState } from '@/store/users';
import { toBoolean } from '@/utils/convert';
import { FilterRequestsByHashtags, MessagesState } from '@/store/messages';

export default Vue.extend({
  name: 'Filters',
  data: () => ({
    filters: [],
    isOnlyFavorites: false,
  }),
  computed: {
    ...mapGetters('tenant', {
      preparedItems: 'itemsForSource',
      getCommunityFilters: 'getFilters',
      getRequestFilters: 'getRequestFilters',
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    ...mapState<UsersState>('users', {
      onlyFavorites: (state: UsersState) => state.stream.onlyFavorites,
      onlyMentors: (state: UsersState) => state.stream.onlyMentors,
      streamFilters: (state: UsersState) => state.stream.filters,
    }),
    ...mapState<MessagesState>('messages', {
      requestsHashtags: (state: MessagesState) => state.requests.hashtags,
      requestsOnlyMy: (state: MessagesState) => state.requests.onlyMy,
    }),
    isTimeSlotsEnabled() {
      return this.isFeatureEnabled('timeSlots');
    },
    isInCommunity() {
      return this.$route.name === 'Community' && !toBoolean(this.$route.query.matching);
    },
    isInHelpRequests() {
      return this.$route.name === 'Requests';
    },
    showFilters() {
      return this.isInCommunity || this.isInHelpRequests;
      // return this.$route.name === 'Community' && !toBoolean(this.$route.query.matching);
    },
    hasSelectedFilters() {
      return this.filters.some((f) => f.value?.length);
    },
    title() {
      if (this.isInCommunity) return this.$t('filters.title');
      else if (this.isInHelpRequests) return this.$t('filters.titleRequests');
      return '';
    },
    doTitle() {
      if (this.isInCommunity) return this.$t('filters.doFilterCommunity');
      else if (this.isInHelpRequests) return this.$t('filters.doFilterRequests');
      return '';
    },
    getFilters() {
      switch (this.$route.name) {
        case 'Community': {
          return this.getCommunityFilters;
        }
        case 'Requests': {
          return this.getRequestFilters;
        }
        default: {
          return () => [];
        }
      }
    },
  },
  watch: {
    getFilters(getFilters) {
      this.filters = getFilters().map((x) => ({ value: undefined, ...x }));
      if (this.isInHelpRequests) {
        this.filters[0].value = this.requestsHashtags.map((tag) => ({ name: tag }));
      }
    },
  },
  methods: {
    async doFilter() {
      if (this.isInCommunity) {
        const preparedFilters = {};
        this.filters.forEach((filter) => {
          if (filter.value && filter.value.length > 0) {
            preparedFilters[filter.name] = filter.value.map((x) => (x.id ? x.id : x.name));
          }
        });
        if (Object.keys(preparedFilters).length) {
          await this.$store.dispatch(new ApplyFilter(preparedFilters));
        }
      } else if (this.isInHelpRequests) {
        const tagsFilter = this.filters.find((filter) => filter.name === 'hashtag');
        if (tagsFilter) {
          await this.$store.dispatch(new FilterRequestsByHashtags(tagsFilter.value.map((value) => value.name)));
        }
      }
    },
    async clearFilter() {
      this.filters.forEach((filter) => (filter.value = undefined));
      if (this.isInCommunity) {
        await this.$store.dispatch(new ApplyFilter({}));
      } else if (this.isInHelpRequests) {
        await this.$store.dispatch(new FilterRequestsByHashtags([]));
      }
    },
    async setFavorites(value) {
      await this.$store.dispatch(new ShowOnlyFavorites(value));
    },
    async setMentors(value) {
      await this.$store.dispatch(new ShowOnlyMentors(value));
    },
    tr(path: string, name: string) {
      return this.$te(`${path}.${name}`) ? this.$t(`${path}.${name}`) : name;
    },
    async deleteChip(index, values) {
      if (values.length > index) {
        values.splice(index, 1);
      }

      if (!this.filters.some((filter) => filter.value && filter.value.length > 0)) {
        await this.clearFilter();
      }
    },
  },
  mounted() {
    this.filters = this.getFilters().map((x) => ({ value: undefined, ...x }));
  },
});
