import { NotificationDialog, NotificationSnackbar, NotificationToast, UIMutations, UIState } from './types';
import { MutationTree } from 'vuex';
import i18n from '@/plugins/i18n';
import deepmerge from 'deepmerge';
import { I18nContent } from '@mentessa/types';

export const mutations: MutationTree<UIState> = {
  [UIMutations.SetNotificationDialog](state: UIState, notificationDialog: NotificationDialog) {
    state.notificationDialog = notificationDialog;
  },
  [UIMutations.SetNotificationSnackbar](state: UIState, notificationSnackbar: NotificationSnackbar) {
    state.notificationSnackbar = notificationSnackbar;
  },
  [UIMutations.SetNotificationToast](state: UIState, notificationToast: NotificationToast) {
    state.notificationToast = notificationToast;
  },
  [UIMutations.SetTranslations](state: UIState, options: { lang: string; content: I18nContent }) {
    i18n.setLocaleMessage(options.lang, deepmerge(i18n.messages[options.lang], options.content));
  },
};
