































import Tabs from '@/components/Tabs/Tab.vue';
import TabCommunity from '@/components/Tabs/TabCommunity.vue';
import TabPersonal from '@/components/Tabs/TabPersonal.vue';
import TabMembers from '@/components/Tabs/TabMembers.vue';
import Title_H2 from '@/components/Typography/Title_H2.vue';
import Vue from 'vue';
import MInvitationLinks from '@/components/Tabs/TabInvitationLinks.vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-settings',

  components: {
    MInvitationLinks,
    Tabs,
    TabCommunity,
    TabPersonal,
    TabMembers,
    Title_H2,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data: () => ({
    border: true,
    tabsNav: [],
  }),
  computed: {
    ...mapState<UsersState>('users', {
      canInvite: (state: UsersState) => state.permissions.canInvite,
    }),
    ...mapGetters('tenant', {
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    isInviteLinksEnabled() {
      return this.canInvite && this.isFeatureEnabled('invites');
    },
    isMembersEnabled() {
      return this.canInvite;
    },
    isCommunityEnabled() {
      return this.canInvite;
    },
  },

  methods: {
    onLogin() {
      this.$emit('onLogin');
    },
    onLogout() {
      this.$emit('onLogout');
    },
    onCreateAccount() {
      this.$emit('onCreateAccount');
    },

    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },
    addMore(category) {
      const categoryLength = this[category].length;
      this[category].push({ value: '', key: `${category}${categoryLength}` });
    },
    toggleFileInput() {
      this.$refs.file.click();
    },
    updateUserImage(e) {
      const file = e.target.files[0];
      this.userImage = URL.createObjectURL(file);
    },
    clearAvatar() {
      this.userImage = '';
    },
    tagsUpdated(data) {
      this[`${data.category}`] = data.value;
    },
  },
  created() {
    this.tabsNav = [
      {
        text: 'Community',
        icon: '',
        id: 'tabs-01',
        enabled: this.isCommunityEnabled,
      },
      {
        text: 'Members',
        icon: '',
        id: 'tabs-03',
        enabled: this.isMembersEnabled,
      },
      {
        text: 'Invitations',
        icon: '',
        id: 'tab-3',
        enabled: this.isInviteLinksEnabled,
      },
    ];
  },
});
