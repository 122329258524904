













































import Vue from 'vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { ApplyFilter } from '@/store/users';

export default Vue.extend({
  name: 'Search',
  data: () => ({
    value: [],
    search: undefined,
    items: [],
    //
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      locations: (state: TenantState) => state.locations,
      usernames: (state: TenantState) => state.usernames,
      tags: (state: TenantState) => state.tenant?.tags,
    }),
  },
  watch: {
    search(token) {
      if (token == null || token === '') {
        this.items = [...this.value];
        return;
      }
      const items = [...this.value];
      if ('mentors'.includes(token.toLowerCase())) {
        items.push({ src: 'mentors', name: 'Mentors' });
      }
      ['tag', 'location', 'username'].forEach((src) => {
        items.push(
          ...this[`${src}s`]
            .filter((x) => x.name.toLowerCase().includes(token.toLowerCase()))
            .map((x) => ({ src, ...x })),
        );
      });
      this.items = items;
    },
  },
  methods: {
    async doFilter() {
      const preparedFilters = {};
      this.value.forEach((filter) => {
        if (!Array.isArray(preparedFilters[filter.src])) {
          preparedFilters[filter.src] = [];
        }
        preparedFilters[filter.src].push(filter.id ? filter.id : filter.name);
      });
      if (Object.keys(preparedFilters).length) {
        await this.$store.dispatch(new ApplyFilter(preparedFilters));
      }
    },
    async clearFilters() {
      await this.$store.dispatch(new ApplyFilter({}));
    },
    clearSearch() {
      if (this.search != null) {
        this.search = null;
      }
    },
    removeItem(index) {
      this.value.splice(index, 1);
      if (this.value.length === 0) {
        this.clearFilters();
      }
    },
  },
});
