import { UsersLoadingPage, UsersMutations, UsersState } from './types';
import { MutationTree } from 'vuex';
import { UserEntity } from '@mentessa/types';

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.AddStreamPage](state: UsersState, data: UsersLoadingPage) {
    data.items.forEach((user) => {
      if (!user.identity.attributes.image) {
        user.identity.attributes.image = '/user-empty.png';
      }
    });
    state.stream.users.push(...data.items);
    Object.assign(state.stream.pagination, data.meta);
  },

  [UsersMutations.AddSearchPage](state: UsersState, data: UsersLoadingPage) {
    state.search.users.push(...data.items);
    Object.assign(state.search.pagination, data.meta);
  },

  [UsersMutations.SetMembers](state: UsersState, data: UsersLoadingPage) {
    data.items.forEach((user) => {
      if (!user.identity.attributes.image) {
        user.identity.attributes.image = '/user-empty.png';
      }
    });
    state.members.users = data.items;
    Object.assign(state.members.pagination, data.meta);
  },

  [UsersMutations.SetStreamLoadingFlag](state: UsersState, isLoading: boolean) {
    state.stream.isLoading = isLoading;
  },
  [UsersMutations.SetSearchLoadingFlag](state: UsersState, isLoading: boolean) {
    state.search.isLoading = isLoading;
  },
  [UsersMutations.SetMembersLoadingFlag](state: UsersState, isLoading: boolean) {
    state.members.isLoading = isLoading;
  },
  [UsersMutations.SetMe](state: UsersState, user: UserEntity) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.me = user;
  },
  [UsersMutations.SetDetailedUser](state: UsersState, user: UserEntity) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.detailedUser = user;
  },
  [UsersMutations.SetUserField](state: UsersState, dst) {
    const path = dst.field.split('.');
    let userPart = state.me;
    for (let i = 0; i < path.length - 1; ++i) {
      if (!Object.prototype.hasOwnProperty.call(userPart, path[i])) {
        userPart[path[i]] = {};
      }
      userPart = userPart[path[i]];
    }
    userPart[path[path.length - 1]] = dst.value;
  },
  [UsersMutations.SetStreamFilters](state: UsersState, filter) {
    state.stream.filters = filter;
  },
  [UsersMutations.SetMembersCurrentPage](state: UsersState, currentPage) {
    state.members.pagination.currentPage = currentPage;
  },
  [UsersMutations.ClearStream](state: UsersState, force = false) {
    if (state.stream.isLoading && !force) {
      console.warn('Trying to clear stream while loading data. Skip');
      return;
    }
    state.stream.users.splice(0);
    state.stream.pagination.currentPage = 0;
    state.stream.pagination.itemCount = 0;
    state.stream.pagination.totalItems = 0;
    state.stream.pagination.totalPages = 0;
  },
  [UsersMutations.SetPermissions](state: UsersState, permissions) {
    state.permissions = permissions;
  },
  [UsersMutations.SetFavoriteStatus](state: UsersState, options: { target: UserEntity; status: boolean }) {
    options.target.isFavorite = options.status;
  },
  [UsersMutations.SetOnlyFavorites](state: UsersState, enabled: boolean) {
    state.stream.onlyFavorites = enabled;
  },
  [UsersMutations.SetOnlyMentors](state: UsersState, enabled: boolean) {
    state.stream.onlyMentors = enabled;
  },
  [UsersMutations.SetSeed](state: UsersState, seed: number) {
    state.seed = seed;
  },
  [UsersMutations.SetSlotAvailable](state: UsersState, { user, weekday, slot, isAvailable }) {
    const foundUser = state.stream?.users?.find((u) => u.id === user.id);
    if (!foundUser) return;
    const foundSlot = foundUser.attributes.timeSlots?.slots[weekday]?.find((s) => s.time === slot.time);
    if (!foundSlot) return;
    foundSlot.available = isAvailable;
  },
  [UsersMutations.SetAdminFlag](state: UsersState, options: { user: UserEntity; isAdmin: boolean }) {
    const streamUser = state.stream?.users?.find((u) => u.id === options.user.id);
    if (streamUser) {
      streamUser.isAdmin = options.isAdmin;
    }
    const membersUser = state.members?.users?.find((u) => u.id === options.user.id);
    if (membersUser) {
      membersUser.isAdmin = options.isAdmin;
    }
  },
  [UsersMutations.RemoveUser](state: UsersState, user: UserEntity) {
    const streamUser = state.stream?.users?.findIndex((u) => u.id === user.id);
    if (streamUser !== -1) {
      state.stream.users.splice(streamUser, 1);
    }
    const membersUser = state.members?.users?.findIndex((u) => u.id === user.id);
    if (membersUser) {
      state.members.users.splice(membersUser, 1);
    }
  },
};
