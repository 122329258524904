import { Action } from '@/store/types';
import { TenantEntity, UserEntity } from '@mentessa/types';

export const moduleName = 'invites';

export interface InviteAttributes {
  name?: string;
  isAdmin?: boolean;
  isMentor?: boolean;
}

export interface InviteEntity {
  id?: number;
  guid?: string;
  isValid?: boolean;
  createdBy?: UserEntity;
  tenant?: TenantEntity;
  invitedUsers?: UserEntity[];
  limit?: number;
  useCount?: number;
  attributes?: InviteAttributes;
  expiredAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface InvitesState {
  all: InviteEntity[];
  current?: InviteEntity;
}

export enum InvitesActions {
  LoadAllInvites = '[Invites] Load All Invites',
  LoadInvite = '[Invites] Load Current Invite',
  CreateInvite = '[Invites] Create Invite',
  DeleteInvite = '[Invites] Delete Invite',
  DisableInvite = '[Invites] Disable Invite',
  UseInvite = '[Invites] Use Invite',
}

export enum InvitesMutations {
  SetAllInvites = '[Invites] Set Invites',
  SetCurrentInvite = '[Invites] Set Current Invite',
}

export class LoadAllInvites implements Action {
  type = `${moduleName}/${InvitesActions.LoadAllInvites}`;
}

export class LoadInvite implements Action {
  type = `${moduleName}/${InvitesActions.LoadInvite}`;
  constructor(private guid: string) {
    this.guid = guid;
  }
}

export class CreateInvite implements Action {
  type = `${moduleName}/${InvitesActions.CreateInvite}`;
  constructor(private invite: InviteEntity) {
    this.invite = invite;
  }
}

export class DeleteInvite implements Action {
  type = `${moduleName}/${InvitesActions.DeleteInvite}`;
  constructor(private invite: InviteEntity) {
    this.invite = invite;
  }
}

export class DisableInvite implements Action {
  type = `${moduleName}/${InvitesActions.DisableInvite}`;
  constructor(private invite: InviteEntity) {
    this.invite = invite;
  }
}

export class UseInvite implements Action {
  type = `${moduleName}/${InvitesActions.UseInvite}`;
  constructor(private invite: InviteEntity, private email: string) {
    this.invite = invite;
    this.email = email;
  }
}
