import { RouteConfig } from 'vue-router';
import { Onboarding } from '@/components/Onboarding';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Onboarding',
    component: Onboarding,
  },
];
