import { GetterTree } from 'vuex';
import { TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';
import { Features, FilterConfig, FiltersConfig } from '@mentessa/types';

const defaultFilters: FilterConfig[] = [
  { name: 'location', itemSource: 'locations' },
  { name: 'expertise', itemSource: 'tags' },
  { name: 'interest', itemSource: 'tags' },
  // { name: 'Mentor Availability', itemSource: '' },
];

export const getters: GetterTree<TenantState, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemsForSource: (state: TenantState) => (source: string) => {
    if (source === 'locations') {
      return state.locations;
    } else if (source === 'usernames') {
      return state.usernames.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      }));
    } else if (source === 'searchUsers') {
      return state.usernames.map((user) => ({
        id: user.id,
        identity: {
          attributes: {
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
          },
        },
        attributes: {},
      }));
    }

    const config: FiltersConfig = state.tenant.config.features.filters;
    const filter = config?.requestFilters?.find((filter) => filter.name === source);
    if (filter) return filter.items.map((item) => ({ name: item }));

    const card = state.tenant.config.userCard.fields.find((card) => card.name === source);
    if (card && card.items) return card.items.map((item) => ({ name: item }));

    return state.tenant[source];
  },
  getFilters: (state: TenantState) => () => {
    const config: FiltersConfig = state.tenant.config.features.filters;
    const items = [];
    if (config?.useDefault ?? true) items.push(...defaultFilters);
    if (config?.filters) items.push(...config.filters);
    return items;
  },
  getRequestFilters: (state: TenantState) => () => {
    const config: FiltersConfig = state.tenant.config.features.filters;
    return config?.requestFilters?.map((filter) => ({ name: filter.name, itemSource: filter.name })) ?? [];
  },
  isFeatureEnabled: (state: TenantState) => (feature: Features) => {
    return state.tenant.config.features[feature]?.enabled ?? false;
  },
  featureConfig: (state: TenantState) => (feature: Features) => {
    return state.tenant.config.features[feature] ?? { enabled: false };
  },
};
