




























import Vue from 'vue';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
});

export default Vue.extend({
  name: 'FormStep2',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({}),
  props: {
    tagLine: String,
  },
  computed: {
    localTagLine: {
      get() {
        return this.tagLine;
      },
      set(value: string) {
        this.$emit('onTagLineChanged', value.trim());
      },
    },
  },
  methods: {
    submitStep(): void {
      this.$emit('onSubmit');
    },
  },
});
