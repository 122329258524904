import { MutationTree } from 'vuex';
import { Matching, MatchingMutations, MatchingState } from './types';
import { UserEntity } from '@mentessa/types';

export const mutations: MutationTree<MatchingState> = {
  [MatchingMutations.SetMatchingDialogVisible](state: MatchingState, visible: boolean) {
    state.matchingDialog.visible = visible;
  },
  [MatchingMutations.SetMatchingDialogLoading](state: MatchingState, isLoading: boolean) {
    state.matchingDialog.isLoading = isLoading;
  },
  [MatchingMutations.SetMatchingLoading](state: MatchingState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [MatchingMutations.SetMatchingUsers](state: MatchingState, users: UserEntity[]) {
    state.users = users;
  },
  [MatchingMutations.SetMatchingEnabled](state: MatchingState, enabled: boolean) {
    state.enabled = enabled;
  },
  [MatchingMutations.SetMatchings](state: MatchingState, matchings: Matching[]) {
    if (matchings) state.matchings = matchings;
    else state.matchings = [];
  },
  [MatchingMutations.SetMatchingIsLoaded](state: MatchingState, isLoaded: boolean) {
    state.isLoaded = isLoaded;
  },
  [MatchingMutations.SetAllMatchings](state: MatchingState, matchings: Matching[]) {
    state.all.matchings = matchings;
  },
  [MatchingMutations.SetAllMatchingsIsLoading](state: MatchingState, loading: boolean) {
    state.all.isLoading = loading;
  },
};
