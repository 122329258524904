<template>
  <v-btn
    :style="`background-color: ${
      step >= index - 1 ? 'var(--v-primary-base)' : '#E7E5E4'
    } !important; margin: 4px 2px; padding: 0; border-radius: 2px;`"
    min-width="16px"
    width="16px"
    max-width="16px"
    height="4px"
    rounded
  >
  </v-btn>
</template>

<script>
export default {
  name: 'StepIndicator',
  props: {
    step: Number,
    index: Number,
  },
};
</script>
