






import Vue from 'vue';

export default Vue.extend({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    maxVisible: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  components: {},
  data: () => ({
    page: null,
  }),
  methods: {
    // onChange() {
    //   this.$emit('onChange');
    //   console.log('change');
    // },
    setPage(page) {
      this.$emit('pageChange', page);
    },
  },
  computed: {
    classes() {
      return {
        pagination: true,
      };
    },
  },
  created: function () {
    this.page = this.currentPage;
  },
});
