








import Vue from 'vue';

export default Vue.extend({
  name: 'Paragraph',
  props: {
    text: {
      type: String,
      required: false,
    },
    weight: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  components: {},
  data: () => ({}),
  computed: {
    classes() {
      return {
        'p--medium': this.weight === 'medium',
        'p--bold': this.weight === 'bold',
        'p--large': this.size === 'large',
        'p--small': this.size === 'small',
      };
    },
  },
});
