












































import Vue from 'vue';
import AppBar from '@/components/AppBar.vue';
import Drawer from '@/components/Drawer.vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { RootState } from '@/store/types';
import NotificationDialog from '@/components/NotificationDialog.vue';
import NotificationSnackbar from '@/components/NotificationSnackbar.vue';
import Toast from '@/components/Toast/Toast.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import MMatchingDialog from '@/components/Matching/MatchingDialog';
import Loader from '@/components/Loader.vue';
import { TenantEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'App',

  components: {
    Loader,
    MMatchingDialog,
    BottomNavigation,
    NotificationSnackbar,
    Toast,
    NotificationDialog,
    Drawer,
    AppBar,
  },

  data: () => ({
    showNav: false,
    miniVariant: true,
  }),

  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
      dataLoaded: (state: RootState) => state.dataLoaded,
    }),
  },
  watch: {
    tenant(tenant: TenantEntity) {
      const theme = this.$vuetify.theme.themes.light;
      const colors = tenant.attributes.theme.colors;
      theme.primary = colors.primary;
      theme.secondary = colors.secondary;
      theme.accent = colors.accent;
      const title = tenant.attributes.theme.title;
      document.title = title ? title : 'Mentessa';
      if (tenant.attributes.theme.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = tenant.attributes.theme.favicon;
      }
    },
  },
  methods: {
    showDrawer() {
      this.showNav = true;
      this.miniVariant = false;
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndUp) {
      this.showNav = true;
    }
    if (this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.lgAndUp) {
      this.miniVariant = false;
    }
  },
});
