










































































































import { Vue } from 'vue-property-decorator';
import { SetFavoriteStatus } from '@/store/users';
import { SendDirectMessage } from '@/store/messages';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'UserDetailMobile',
  props: {
    user: { type: Object as PropType<UserEntity> },
  },
  data: () => ({
    message: '',
  }),
  computed: {
    matchesNumber() {
      return 0;
    },
  },
  watch: {
    $route(to) {
      if (to.name !== 'UserDetails') this.$emit('closeDetails');
    },
  },
  methods: {
    isFavorite() {
      return this.user.isFavorite;
    },
    async toggleFavorite() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },
    async sendMessage() {
      const success = await this.$store.dispatch(new SendDirectMessage(this.user.id, this.message));
      if (success) {
        this.message = '';
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSent')));
      } else {
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSendError')));
      }
    },
    buildUrl(value: string) {
      if (value.startsWith('http://') || value.startsWith('https://')) return value;
      return `https://${value}`;
    },
  },
});
