















































































import Vue from 'vue';
import MAutocompleteUsers from '../../Autocomplete';
import { UserEntity } from '@mentessa/types';
import { MatchingPair } from '@/store/matching';

export default Vue.extend({
  name: 'm-matching-pick-pairs-form',
  components: { MAutocompleteUsers },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pairs: [{ left: undefined, right: undefined }] as MatchingPair[],
    isValid: false,
  }),
  computed: {
    handshakeEmoji() {
      return require('@/assets/handshake-emoji.png');
    },
    excludedUsers() {
      return this.pairs.flatMap((pair) => {
        const r = [];
        if (pair.left) r.push(pair.left);
        if (pair.right) r.push(pair.right);
        return r;
      });
    },
  },
  methods: {
    changeUser(pairIndex: number, userIndex: number, user: UserEntity) {
      if (pairIndex >= this.pairs.length) {
        console.error(`Pair with index ${pairIndex} not exists`);
        return;
      }
      const pair = this.pairs[pairIndex];
      if (userIndex > 1) {
        console.error(`Only 2 users can be paired`);
        return;
      }
      if (userIndex) pair.right = user;
      else pair.left = user;

      this.validate();
    },
    addPair() {
      this.pairs.push({ left: undefined, right: undefined });
    },
    removePair(index) {
      this.pairs.splice(index, 1);
    },
    doMatch() {
      this.$emit('onMatch', this.pairs);
    },
    resetPairs() {
      this.pairs = [{ left: undefined, right: undefined }];
      this.isValid = false;
    },
    validate() {
      let validPairs = 0;
      let invalidPairs = 0;
      this.pairs.forEach((pair) => {
        if (Boolean(pair.left) && Boolean(pair.right)) ++validPairs;
        else if (!pair.left && !pair.right) {
          //
        } else ++invalidPairs;
      });
      this.isValid = validPairs > 0 && invalidPairs === 0;
    },
  },
});
