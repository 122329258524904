import { GetterTree } from 'vuex';
import { UsersState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<UsersState, RootState> = {
  isAllStreamUsersLoaded(state: UsersState): boolean {
    const {
      stream: {
        pagination: { currentPage: current, totalPages: total },
      },
    } = state;
    return current !== 0 && current >= total;
  },
  isNewPageAvailable(state: UsersState, getters): boolean {
    const {
      stream: { isLoading },
    } = state;
    return !(isLoading || getters.isAllStreamUsersLoaded);
  },
  hasFilters(state: UsersState) {
    return Object.keys(state.stream?.filters).length > 0;
  },
  getUserById(state: UsersState) {
    return (id: number) => {
      return state.stream?.users?.find((user) => user.id === id);
    };
  },
  getPaginationSize(state: UsersState) {
    const {
      stream: { pagination },
    } = state;
    return pagination;
  },
};
