






















import Vue from 'vue';

export default Vue.extend({
  name: 'Dialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (this.value !== newValue) this.$emit('input', newValue);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
  },
});
