
















import { mapState } from 'vuex';
import { UIState } from '@/store/ui';
import { Vue } from 'vue-property-decorator';
import { ShowNotificationToastAction } from '@/store/ui';
import { notificationOptions } from '@/config';

export default Vue.extend({
  name: 'Toast',
  props: {
    text: {
      type: String,
      required: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
    },
    success: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data: () => ({
    snackbar: false,
    timeout: 2000000,
  }),
  methods: {
    close() {
      this.$store.dispatch(new ShowNotificationToastAction('', ''));
    },
  },
  computed: {
    ...mapState<UIState>('ui', {
      message: (state: UIState) => state.notificationToast.message,
      state: (state: UIState) => state.notificationToast.state,
    }),
    classes() {
      return {
        toast: true,
      };
    },
  },
  watch: {
    message(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$store.dispatch(new ShowNotificationToastAction('', ''));
        }, notificationOptions.snackbar.showTime);
      }
    },
  },
});
