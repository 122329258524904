


























import Paragraph from '@/components/Typography/Paragraph.vue';
import Button from '@/components/Buttons/Button.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'FileDropzone',
  components: {
    Paragraph,
    Button,
  },

  data() {
    return {
      file: '',
    };
  },
  methods: {
    toggleFileInput() {
      this.$refs.file.click();
    },
    updateValue(e) {
      const file = e.target.files[0];
      this.file = URL.createObjectURL(file);
    },
    onDrop(e) {
      this.dragover = false;
      console.log(e);

      this.$emit('onFileUpload', e.dataTransfer.files);
      //e.dataTransfer.files.forEach((element) => this.uploadedFiles.push(element));
    },
  },
});
