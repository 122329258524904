






























import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'InviteTextareaInput',
  props: {
    errors: { type: Array as PropType<string[]>, default: () => [] },
    value: { type: Array as PropType<string[]>, default: () => [] },
  },
  data: () => ({}),
  computed: {
    localEmails: {
      get() {
        return this.value.join('\n');
      },
      set(value: string) {
        const emails = value
          .split(/[,\n]/)
          .map((email) => email.trim())
          .filter((email) => email);
        this.$emit('input', emails);
        this.$emit('changed', emails);
      },
    },
  },
  watch: {
    localEmails(value: string) {
      const emails = value.split(/[,\n]/).filter((email) => email);
      this.$emit('input', emails);
      this.$emit('changed', emails);
    },
  },
  methods: {
    changeInviteMethod() {
      this.$emit('changeInviteMethod');
    },
  },
});
