import { format, parseISO } from 'date-fns';

export function parseAndFormatDate(date: string): string {
  try {
    return format(parseISO(date), 'd.M.yyyy');
  } catch (e) {
    return '-';
  }
}

export function parseAndGetTime(date: string): number {
  return parseISO(date).getTime();
}
