


















import Vue, { PropType } from 'vue';
import MAutocompletePure from './AutocompletePure.vue';
import MUserPreview from '../UserPreview';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
/*

ToDo: Add spacing. 16px between lines and from left/right

 */

export default Vue.extend({
  name: 'm-autocomplete-users-pure',
  components: { MUserPreview, MAutocompletePure },
  props: {
    // label: String,
    // placeholder: String,
    items: Array as PropType<UserEntity[]>,
    value: { type: Object as PropType<UserEntity>, default: () => ({}) },
    exclude: { type: Array as PropType<UserEntity[]>, default: () => [] },
  },
  computed: {
    filteredUsers() {
      const excludeIds = this.exclude.map((user) => user.id);
      return this.items.filter((user) => user.id === this.value?.id || !excludeIds.includes(user.id));
    },
  },
  methods: {
    userFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      return this.fullName(item).toLowerCase().includes(searchText);
    },
    fullName(user: UserEntity) {
      return getFullName(user);
    },
    changeModel(newValue) {
      this.$emit('input', newValue);
    },
  },
});
