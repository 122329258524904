







import Vue, { PropType } from 'vue';
import Dialog from '../Dialog';
import { Matching, MatchingPair } from '@/store/matching';
import MMatchingPickPairsForm from './forms/MatchingPickPairsForm.vue';
import MMatchingMessageForm from './forms/MatchingMessageForm.vue';

export enum MatchingDialogStep {
  PickUsers = 0,
  SendMessage = 1,
}

export default Vue.extend({
  name: 'm-matching-dialog-pure',
  components: { MMatchingMessageForm, MMatchingPickPairsForm, Dialog },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: MatchingDialogStep.PickUsers,
    },
    matchings: {
      type: Array as PropType<Matching[]>,
      default: () => [],
    },
  },
  data: () => ({
    pairs: [{ left: undefined, right: undefined }] as MatchingPair[],
    isValid: false,
  }),
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
    isPickUsersStep() {
      return this.step === MatchingDialogStep.PickUsers;
    },
  },
});
