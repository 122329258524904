var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex image-input justify-center",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'align-content-end'},[_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mx-auto' : ''},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',{staticClass:"avatar",attrs:{"tile":""},on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false}}},[_c('v-img',{staticClass:"avatar-image",class:{ hovering: _vm.isHovering },staticStyle:{"justify-content":"center","align-items":"center"},style:('border: 3px solid ' + _vm.$vuetify.theme.themes.light.primary + '; border-radius: 5px;'),attrs:{"src":_vm.value,"contain":"","elevation":hover ? 12 : 2},on:{"click":_vm.editImage}})],1)]}}])}),(_vm.$route.path === '/settings' && this.label)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$vuetify.lang.t(this.label)))]):_vm._e()],1),_c('div',{staticClass:"d-flex align-end py-3 m-image-input-hint",class:_vm.$vuetify.breakpoint.xsOnly ? 'text-center' : ''},[_c('p',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mx-auto' : '',staticStyle:{"color":"#757575"},domProps:{"innerHTML":_vm._s(_vm.hint)}})]),_c('v-dialog',{attrs:{"width":"500","height":"500"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('div',{staticStyle:{"position":"relative","height":"500px"}},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image,"stencil-size":{
          width: 400,
          height: 400,
        },"stencil-props":{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        },"image-restriction":"stencil"}}),_c('div',{staticStyle:{"position":"absolute","top":"15px","right":"17px","cursor":"pointer"},on:{"click":function($event){_vm.showEditDialog = false}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.0327 8L15.5814 3.45136C16.1395 2.89318 16.1395 1.98818 15.5814 1.42955L14.5705 0.418636C14.0123 -0.139545 13.1073 -0.139545 12.5486 0.418636L8 4.96727L3.45136 0.418636C2.89318 -0.139545 1.98818 -0.139545 1.42955 0.418636L0.418636 1.42955C-0.139545 1.98773 -0.139545 2.89273 0.418636 3.45136L4.96727 8L0.418636 12.5486C-0.139545 13.1068 -0.139545 14.0118 0.418636 14.5705L1.42955 15.5814C1.98773 16.1395 2.89318 16.1395 3.45136 15.5814L8 11.0327L12.5486 15.5814C13.1068 16.1395 14.0123 16.1395 14.5705 15.5814L15.5814 14.5705C16.1395 14.0123 16.1395 13.1073 15.5814 12.5486L11.0327 8Z","fill":"var(--v-primary-base)"}})])]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","width":"100%","position":"absolute","bottom":"0"}},[_c('v-btn',{staticClass:"ma-2 mr-1",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.selectImage($event)}}},[_c('v-file-input',{ref:"fileInput",staticClass:"d-none",attrs:{"accept":"image/png, image/jpeg, image/bmp"},on:{"change":function($event){return _vm.loadImage($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-icon',{attrs:{"center":""}},[_vm._v(" cloud_upload ")])],1),_c('v-btn',{staticClass:"ma-2 ml-1",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.applyImage($event)}}},[_vm._v(" "+_vm._s(_vm.$t('user.applyImage'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }