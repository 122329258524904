import Linkify from 'linkify-it';

const linkify = Linkify();

export function linkifyHtml(html: string): string {
  const result = [];
  let last = 0;
  const matches = linkify.match(html) ?? [];

  matches.forEach(function (match) {
    if (last < match.index) {
      result.push(html.slice(last, match.index));
    }
    result.push(`<a target="_blank" href="${match.url}">${match.text}</a>`);
    last = match.lastIndex;
  });
  if (last < html.length) {
    result.push(html.slice(last));
  }

  return result.join('');
}
