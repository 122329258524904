


























































































import { Vue } from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import { UserEntity } from '@mentessa/types';
import { PropType } from 'vue';
import { ForceSeed, LoadNextStreamPage, UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import UserDetail from '@/components/UserDetail.vue';
import UserDetailMobile from '@/components/UserDetailMobile.vue';
import Loader from '@/components/Loader.vue';
import { SendDirectMessage } from '@/store/messages';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import { LoadMatchingUsers, MatchingState } from '@/store/matching';

export default Vue.extend({
  name: 'UserStream',
  components: {
    Loader,
    UserDetail,
    UserDetailMobile,
    UserCard,
  },
  props: {
    users: { type: Array as PropType<UserEntity[]>, default: () => [] },
    matchingMode: { type: Boolean, default: false },
  },
  data: () => ({
    loadingTrigger: false,
    showUserDetails: false,
    messageDialog: false,
    messageText: '',
    detailCarouselIndex: 0,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      isStreamLoading: (state: UsersState) => state.stream.isLoading,
      streamCurrentPage: (state: UsersState) => state.stream.pagination.currentPage,
    }),
    ...mapGetters('users', {
      hasFilters: 'hasFilters',
      isNewStreamPageAvailable: 'isNewPageAvailable',
    }),
    ...mapGetters('matching', {
      isNewMatchingPageAvailable: 'isNewPageAvailable',
    }),
    ...mapState<MatchingState>('matching', {
      isMatchingLoading: (state: MatchingState) => state.isLoading,
    }),
    isNewPageAvailable() {
      if (this.matchingMode) return this.isNewMatchingPageAvailable;
      else return this.isNewStreamPageAvailable;
    },
    isLoading() {
      if (this.matchingMode) return this.isMatchingLoading;
      else return this.isStreamLoading;
    },
  },
  watch: {
    showUserDetails(isDialogOpen) {
      const query = this.$route.query;
      if (!isDialogOpen) {
        this.$router.replace({ name: 'Community', query });
      }
    },
    matchingMode() {
      // console.log(
      //   222,
      //   this.matchingMode,
      //   this.streamCurrentPage === 0,
      //   this.matchingMode || this.streamCurrentPage === 0,
      // );
      // if (this.matchingMode) {
      //   console.log(333, this.matchingMode || this.streamCurrentPage === 0);
      //   this.loadingTrigger = false;
      //   this.checkContent();
      // }
    },
  },
  methods: {
    async loadNextPage() {
      if (this.matchingMode) {
        await this.$store.dispatch(new LoadMatchingUsers());
      } else {
        await this.$store.dispatch(new LoadNextStreamPage());
      }
    },
    // ToDo: Fix loader
    async checkContent(/*entries, observer, isIntersecting*/) {
      // if (!this.matchingMode && this.streamCurrentPage !== 0) return;
      this.loadingTrigger = !this.loadingTrigger;
      if (this.loadingTrigger && this.isNewPageAvailable) {
        setTimeout(async () => {
          const usersCount = this.users.length;
          if (usersCount === 0 && this.$route.name === 'UserDetails') {
            await this.$store.dispatch(new ForceSeed(Number(this.$route.params.id)));
          }

          await this.loadNextPage();
        }, 500);
      }
    },
    async detailCarouselChangeItem(index) {
      if (index === -1) {
        return;
      }
      const desiredId = this.users[index].id.toString();
      const currentId = this.$route.params.id;
      if (index === this.users.length - 1 && this.isNewPageAvailable) {
        await this.loadNextPage();
      }
      if (desiredId !== currentId) {
        const query = this.$route.query;
        await this.$router.replace({
          name: 'UserDetails',
          params: { id: desiredId.toString() },
          query: query,
        });
      }
    },
    openUserDetails(userId) {
      const userIndex = this.users.findIndex((user) => user.id === userId);
      const query = this.$route.query;
      this.$router.replace({
        name: 'UserDetails',
        params: { id: userId.toString() },
        query,
      });
      this.detailCarouselIndex = userIndex;
      this.showUserDetails = true;
    },
    closeDetails() {
      this.showUserDetails = false;
    },
    closeMessageDialog() {
      this.messageDialog = false;
      this.messageText = '';
    },
    async sendMessage(userId) {
      const success = await this.$store.dispatch(new SendDirectMessage(userId, this.messageText));
      if (success) {
        this.messageText = '';
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSent')));
      } else {
        console.log('Cant send message');
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSendError')));
      }
    },
  },
  created() {
    if (this.$route.name === 'UserDetails') {
      this.showUserDetails = true;
    }
  },
});
