




































































import Vue from 'vue';
import { UploadImage } from '@/store/users';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Hermite_class from './hermite';

function getFileFromCanvas(canvas, fileName): Promise<File> {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = canvas.width;
  tmpCanvas.height = canvas.height;
  const destCtx = tmpCanvas.getContext('2d');
  destCtx.drawImage(canvas, 0, 0);
  const HERMITE = new Hermite_class();
  HERMITE.resample_single(tmpCanvas, 512, 512, true);
  return new Promise((resolve) => {
    tmpCanvas.toBlob(function (blob) {
      resolve(new File([blob], fileName));
    });
  });
}

export default Vue.extend({
  name: 'ImagePicker',
  components: {
    Cropper,
  },
  props: {
    value: String,
    hint: String,
  },
  data: function () {
    return {
      file: {},
      isHovering: false,
      showEditDialog: false,
      image: undefined,
    };
  },
  watch: {
    showEditDialog(isVisible) {
      if (!isVisible) {
        this.file = null;
      }
    },
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.$refs.input.click();
    },
    loadImage(event) {
      if (event) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(event);
      }
    },
    async applyImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const name = this.file?.name || this.value;
        const file = await getFileFromCanvas(canvas, name);
        const imageUrl = await this.$store.dispatch(new UploadImage(file));
        this.$emit('change', `/api${imageUrl}`);
        this.$emit('input', `/api${imageUrl}`);
        this.showEditDialog = false;
      }
    },
    editImage() {
      this.image = this.value;
      this.showEditDialog = true;
    },
  },
});
