




































import Paragraph from '../Typography/Paragraph.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'TextareaTags',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    // value: {
    //   type: String,
    //   required: false,
    // },
    state: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    fieldName: {
      type: String,
      required: false,
    },
    tags: {
      type: Array,
      required: false,
    },
  },
  components: { Paragraph },
  data: () => ({
    select: [],
    items: [],
    search: '', //sync search
  }),
  methods: {
    onChange() {
      this.$emit('onChange');
    },
    updateTags() {
      // console.log(11);
      // const tags = this.tags;
      // console.log(tags);
      console.log(this.select);
      console.log(this.search);
      //       this.$nextTick(() => {
      //         this.select.push(...this.search.split(','));
      //         this.$nextTick(() => {
      //           this.search = '';
      //         });
      //
      //       });
      this.$emit('tagsUpdated', { category: this.fieldName, value: this.select });

      //console.log(this.select);
    },
  },
  computed: {
    classes() {
      return {
        'form-element': true,
      };
    },
  },
  created: function () {
    console.log(this.tags);
    this.select.push(...this.tags);
  },
});
