




import Vue from 'vue';

export default Vue.extend({
  name: 'Title_H5',
  props: {
    text: {
      type: String,
      required: true,
    },
    weight: {
      type: String,
      required: true,
    },
  },
  components: {},
  data: () => ({}),
  computed: {
    classes() {
      return {
        'h5--medium': this.weight === 'medium',
        'h5--bold': this.weight === 'bold',
      };
    },
  },
});
